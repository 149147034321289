export interface ProjectVisualizationDatabaseResponse {
  employeeId?: number;
  employeeName?: string;
  projectId?: number;
  projectName?: string;
  activityId?: number;
  activityName?: string;
  value: string;
}

export interface EmployeesStackedSeries {
  id: number;
  name: string;
  type: 'employee';
  series: {
    id: number;
    name: string;
    value: number;
    type: 'project';
  }[];
}
export interface ProjectsBarSeries {
  name: string;
  value: number;
  extra: {
    id: number;
    type: 'project';
  };
}

export interface EmployeesBarSeries {
  name: string;
  value: number;
  extra: {
    id: number;
    type: 'employee';
  };
}

export interface ActivitiesBarSeries {
  name: string;
  value: number;
  extra: {
    id: number;
    type: 'activity';
  };
}

export interface ProjectVisualizationGetRequest {
  employees?: number[];
  projects?: number[];
  contractTypes?: number[];
  inactive?: boolean | string;
  from?: number;
  to?: number;
}

export type ProjectVisualizationGetResponse =
  | ProjectsBarSeries
  | EmployeesStackedSeries
  | EmployeesBarSeries
  | ActivitiesBarSeries;
