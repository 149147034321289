import {
  Component, Inject, Input,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { FeatureOf, FEATURES } from '@ceres/feature-toggles';
import { CeresFeaturesConfig } from '../../../../features/interfaces/ceres-features-config.interface';
import { AppUserService } from '@ceres/shared/services';
import { rolePermissions } from '@ceres/domain';

@Component({
  selector: 'ceres-inline-navbar',
  templateUrl: './inline-navbar.component.html',
  styleUrls: ['./inline-navbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InlineNavbarComponent implements OnInit {
  public permission = rolePermissions;

  @Input() navigationModule: string;
  @Input() currentRoute?: string;

  constructor(
    @Inject(FEATURES) public readonly features: FeatureOf<CeresFeaturesConfig>,
    public readonly appUserService: AppUserService
  ) {}

  ngOnInit() {
  }
}
