import { formatCurrency, getCurrencySymbol } from '@angular/common';
import {
  DEFAULT_CURRENCY_CODE,
  Inject,
  LOCALE_ID,
  Optional,
  Pipe,
  PipeTransform
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import isObject from 'lodash-es/isObject';
import { ExchangeRateScopeService } from '../services/exchange-rate-scope.service';

type PipeOptions = Partial<{
  currencyCode: string;
  exchangeRate:
    | number
    | {
        value: number;
      };
}>;

@Pipe({ name: 'convertCurrency', pure: false })
export class ConvertCurrencyPipe implements PipeTransform {
  constructor(
    @Inject(LOCALE_ID) private readonly defaultLcid: string,
    @Inject(DEFAULT_CURRENCY_CODE) private readonly defaultCurrencyCode: string,
    private readonly translationService: TranslocoService,
    @Optional() private readonly exchangeRateScope: ExchangeRateScopeService
  ) {}

  get lcid() {
    return this.translationService.getActiveLang() || this.defaultLcid;
  }

  transform(
    value: number,
    options: PipeOptions = (this.exchangeRateScope ? this.exchangeRateScope.value : undefined)
  ) {
    if (!options || options && !options.currencyCode) {
      return formatCurrency(
        value,
        this.lcid,
        getCurrencySymbol(this.defaultCurrencyCode, 'wide'),
        this.defaultCurrencyCode
      );
    }

    const rate = isObject(options.exchangeRate)
      ? options.exchangeRate.value
      : options.exchangeRate;

    return formatCurrency(
      value * rate,
      this.lcid,
      getCurrencySymbol(options.currencyCode, 'wide'),
      options.currencyCode
    );
  }
}
