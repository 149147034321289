import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import { ListItem } from "@ceres/frontend-helper";

@Component({
  selector: 'ceres-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiSelectComponent implements OnChanges {
  @Input() itemsList!: ListItem[];
  @Input() icon!: string;
  @Input() name!: string;
  @Input() triggered!: boolean;
  @Input() translationPrefix: string = 'columns.';
  @Output() applySelected: EventEmitter<{ selectedColumns: ListItem[] }> = new EventEmitter();

  ngOnChanges(changes: any) {
    if (changes.itemsList && (changes.itemsList.currentValue !== changes.itemsList.previousValue)) {
      this.itemsList = changes.itemsList.currentValue;
    }
  }

  applyColumns() {
    this.applySelected.emit({
      selectedColumns: this.itemsList
        .filter(sc => sc.selected)
    });
  }

  markAllColumns(selected: boolean, ev: MouseEvent) {
    this.itemsList.forEach(ac => ac.selected = selected);
    ev.stopPropagation();
  }

  preventClose(ev: MouseEvent) {
    ev.stopPropagation();
  }
}
