import { Folder } from './folder';
import { Employee } from './employee';

export enum DocumentStatus {
  ARCHIVED = 'archived',
  ACTIVE = 'active'
}
export interface Document {
  id: number;
  name: string;
  folder?: Folder;
  folderId: number;
  archived: boolean;
  author?: Employee;
  authorId: number;
  createdAt: Date;
  updatedAt: Date;
  key: string;
  contentType: string;
}

export interface DocumentCreateRequest {
  name: string;
  folderId: number;
  key: string;
  contentType: string;
  archived?: boolean;
}

export interface SignedURLUploadRequest {
  name: string;
  folderId: number;
  contentType: string;
}

export interface SignedURLTranslationsRequest {
  file: string;
  stage: string;
}

export interface SignedURLUploadResponse {
  key: string;
  url: string;
  name: string;
}

export interface ConfirmUpload {
  key: string;
}

export interface SignedURLDownloadResponse {
  key: string;
  url: string;
  name: string;
}

export interface CopyDocumentsRequest {
  documentIds: number[];
  targetFolderId: number;
}

export interface DeleteDocumentsResponse {
  documentIds: number[];
}

export interface PatchDocumentRequest {
  archived: boolean;
}


// Deprecated
export interface SPFile {
  Id: number;
  ServerRelativeUrl: string;
  Name: string;

  Author: { uri: string };
  CheckedOutByUser: { uri: string };
  LockedByUser: { uri: string };
  ModifiedBy: { uri: string };
  Versions: object;
  CheckInComment: string;
  CheckOutType: number;
  ContentTag: string;
  CustomizedPageStatus: number;
  ETag: string;
  Exists: boolean;
  Length: string;
  Level: number;
  MajorVersion: number;
  MinorVersion: number;
  TimeCreated: Date | string;
  TimeLastModified: Date | string;
  Title: string;
  UIVersion: number;
  UIVersionLabel: string;
}

