import {Activity} from './activity';

export interface Module {
  id: number;
  personalId: number;
  title: string;
  originalTitle: string;
  subtitle: string;
  originalSubtitle: string;
  activities: { activity: Activity, hours: number, isNew: boolean }[];
  originalActivities: { activity: Activity, hours: number }[];
  count: number;
  originalCount: number;
  price: number;
  originalPrice: number;
  comment: string;
  originalComment: string;
}

export interface Package {
  id: number;
  personalId: number;
  mpNumber: number;
  title: string;
  modules: Module[];
  originalTitle: string;
  originalModules: Module[];
}
