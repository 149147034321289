import { Portfolio } from './portfolio';
import { Employee } from './employee';

export interface Task {
  id: number;
  title: string;
  description: string;
  employees: Employee[];
  status: string;
  module: string;
  created: Date;
  modified: Date;
  dueDate: Date;
  portfolio: Portfolio;
  action: string;
  EmployeesId: number[];
  itemId: number;
  updatedAt?: Date;
  createdAt?: Date;
}
