import { Pipe, PipeTransform } from '@angular/core';
import { Employee } from '@ceres/domain';

@Pipe({
  name: 'employeesHaveAssignedActivities'
})
export class EmployeesHaveAssignedActivitiesPipe implements PipeTransform {
  transform(employees: Employee[]): boolean {
    if (employees) {
      return !employees.some(employee =>
        employee.assignedActivities
          ? employee.assignedActivities.length === 0
          : true
      );
    }
    return false;
  }
}
