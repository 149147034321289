import { UnconvertedBusinessPartner } from './business-partner';
import { ServiceProduct } from './service-product';
import { Department } from './department';

import { ForecastMethod } from '../enums/forecast-method.enum';
import { Currency } from './currency';
import { Employee } from './employee';
import { PricingMethod } from '../enums/pricing-method.enum';
import { BusinessArea } from './business-area';
import { Portfolio } from './portfolio';

export interface Service {
  id: number;
  serviceNumber: string;
  country?: string;
  internalCustomerName?: string;
  are?: string;
  comment?: string;
  originDC?: string;
  businessArea?: BusinessArea
  cc?: string;
  profitCenter?: string;
  localMN?: string;
  department?: Department | null;
  businessPartner?: UnconvertedBusinessPartner | null;
  crmKam?: Employee | null;
  endDate: string;
  startDate: string;
  bviUploadFileNameMapper?: string;
  name: string;
  service: ServiceProduct;
  orgId: string;
  po: string;
  poPosition: number;
  customerOrgId: string;
  createdAt?: string;
  lastUpdatedAt?: string;

  pricePerUnit: number;
  pricePerNrOfUnits: number;
  pricingMethod: PricingMethod;
  unitsAnnualVolume: number;
  localCurrency: Currency;
  forecastMethod: ForecastMethod;
  billingPlatform: boolean;
  toBeCharged: boolean;
  unitType?: string;
  mvc?: number;
  portfolio?: Portfolio;

  additionalInfo1?: string;
  additionalInfo2?: string;
  additionalInfo3?: string;
  markedForDeletion: boolean;
}

export interface ServiceAccountingData {
  // from service
  serviceId: number;
  orgId: string;
  po: string;
  country: string;
  localMN: string;
  poPosition: number;
  bviPeriod: number;
  pricePerUnit: number;
  localCurrency: string;
  profitCenter: string;
  text01: string;
  serviceLineItem: string;
  name: string;
  gbsServiceId: string;
  pricingMethod: string;
  crmKam: string;
  department: string;
  are: string;
  p: string;
  startDate: string;
  endDate: string;

  //permanents
  salesOff: string;
  docType: string;
  exportControl1: string;
  exportControl2: string;
  contactPerson: string;
  overHeadKey: string;
  text10: string;
  costCenter: string;
  servDate: string;
  refDoc: string;
  costingSheet: string;
}

export interface MonthlyValues {
  id: number | undefined;
  serviceId: number;
  p1?: number;
  p2?: number;
  p3?: number;
  p4?: number;
  p5?: number;
  p6?: number;
  p7?: number;
  p8?: number;
  p9?: number;
  p10?: number;
  p11?: number;
  p12?: number;
  bviP1?: number;
  bviP2?: number;
  bviP3?: number;
  bviP4?: number;
  bviP5?: number;
  bviP6?: number;
  bviP7?: number;
  bviP8?: number;
  bviP9?: number;
  bviP10?: number;
  bviP11?: number;
  bviP12?: number;
}

export type ManualForecast = MonthlyValues;

export type Actuals = MonthlyValues;

export interface ServicesFilterValues {
  name: string[];
  serviceLineItem: string[];
  gbsServiceId: string[];
  serviceNumber: string[];
  country: string[];
  are: string[];
  dc: string[];
  cc: string[];
  profitCenter: string[];
  crmKam: string[];
  localMN: string[];
  internalCustomerName: string[];
  localCurrency: string[];
  pricingMethod: string[];
  forecastMethod: string[];
  department: string[];
  team: string[];
  portfolio: string[];
  originDC: string[];
  orgId: string[];
  toBeCharged: boolean[];
  billingPlatform: boolean[];
  po: string[];
  poPosition: number[];
}
