<ng-container *ngIf="!multiple; else multipleAlternative">
  <mat-form-field class="pwo-form picker">
    <input matInput [required]="required" placeholder="{{placeholder}}" [value]=" value ? value.title : ''"
      [ngClass]="{'input-invalid': condition === true}" readonly>
    <ng-container *ngIf="!readonly">
      <ceres-button
        *ngIf="!value || !value.title; else other"
        [ngClass]="{'invalid': condition === true}"
        (click)="openProgramSelection()"
        [layout]="'picker'">
        <i class="material-icons">add</i>
      </ceres-button>
      <ng-template #other>
        <ceres-button
          (click)="removeSelected(null)"
          [layout]="'picker'">
          <i class="material-icons">delete</i>
        </ceres-button>
      </ng-template>
    </ng-container>
  </mat-form-field>
</ng-container>
<ng-template #multipleAlternative>
  <mat-form-field class="w-100">
    <mat-chip-list #chipList>
      <mat-chip (removed)="removeSelected(program)" *ngFor="let program of value">
        {{ program.title }}
        <mat-icon *ngIf="!readonly" matChipRemove>cancel</mat-icon>
      </mat-chip>
      <mat-chip (click)="openProgramSelection(true)" *ngIf="!readonly">
        <mat-icon>add</mat-icon>
      </mat-chip>
      <input [disabled]="readonly" [matChipInputFor]="chipList" placeholder="{{placeholder}}" readonly>
    </mat-chip-list>
  </mat-form-field>
</ng-template>
