<div
  [ngClass]="{'filter-active': filterBuilder.recordFilter[finalColumn] && filterBuilder.recordFilter[finalColumn].length}"
  class="filter-content">

  <ng-container *ngIf="finalColumn !== 'isFavorite' && finalColumn !== 'status-ico'">
    <span *ngIf="sort" class="filter-title" mat-sort-header>{{ 'columns.' + finalColumn | transloco }}</span>
    <span *ngIf="!sort" class="filter-title">{{ 'columns.' + finalColumn | transloco }}</span>
  </ng-container>
  <i *ngIf="finalColumn === 'isFavorite'" class="material-icons" mat-sort-header>star</i>
  <i *ngIf="finalColumn== 'status-ico'" class="material-icons">lock</i>

  <span class="header-icon" (click)="collapse(null, $event)">
    <i #down class="material-icons">keyboard_arrow_down</i>
    <i #up [style.display]="'none'" class="material-icons">keyboard_arrow_up</i>
  </span>

  <div #show (mouseenter)="collapse(false)" (mouseleave)="collapse(true)" [style.display]="'none'"
    [style.left.px]="element?.offsetLeft" [style.min-width.px]="element?.clientWidth + 100" class="show">
    <div [style.height.px]="element?.clientHeight + 1.5" [style.width.px]="element?.clientWidth + 1" class="top-copy">
    </div>
    <div class="bottom-part">
      <div class="filter-header">
        <div class="pwo-input">
          <input (click)="preventClose($event)" [formControl]="searchField" autocomplete="off" matInput
            placeholder="{{'shared.miscellaneous.search-dots' | transloco}}">
          <button (click)="deleteValue($event)" *ngIf="filteredValues" aria-label="Clear" mat-button mat-icon-button
            matSuffix>
            <i class="material-icons">close</i>
          </button>
        </div>
      </div>

      <div (click)="preventClose($event)" *ngIf="getValues()" class="filter-values">
        <mat-checkbox (change)="selectAll()" *ngIf="!fewSelected" [(ngModel)]="allChecked">
          {{'shared.buttons.select-all' | transloco}}</mat-checkbox>
        <mat-checkbox (change)="selectAll()" *ngIf="fewSelected" class="mat-checkbox-semichecked">
          {{'shared.buttons.select-all' | transloco}}</mat-checkbox>
        <div *ngFor="let col of getValues()">
          <mat-checkbox (change)="checkIfAllChecked(getValues());" [(ngModel)]="col.selected">
            {{col.title === 'empty' ? ('default.empty' | transloco) : col.title.endsWith ? col.title.endsWith('Z') && col.title.includes(':') ? (col.title+'' | localizedDate) : (col.title + '' | transloco) : (col.title + '' | transloco)}}
          </mat-checkbox>
        </div>
      </div>

      <div class="filter-apply">
        <button (click)="collapse(true); applyColumns()" [disabled]="isDisabled"
                class="pwo dd-with-icon pwo--150">
          <div class="container">
            <i class="material-icons icon">done</i><span>{{'shared.buttons.apply' | transloco}}</span>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>
