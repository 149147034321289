import { ProjectProfile } from './project-profile';
import { Employee } from './employee';
import { Workload } from './workload';
import { CapacityTypeEnum } from '../enums/capacity-type.enum';

export type AccountingRow = Partial<ProjectProfile & {
  capacity: Capacity[];
  workload: Workload[];
  projectComment: string;
  sum: number[];
}>;

export interface Capacity {
  id: number;
  comment: string;
  mpNumber: number;
  year: number;
  from: number;
  to: number;
  weekNo: number;
  hours: number;
  employee: Employee;
  project: ProjectProfile;
  dailyCapacities: CapacityDaily[];

  originalHours: number;
  originalComment: string;
}

export interface CapacityDaily {
  id: number;
  comment?: string;
  hours: number;
  date: Date;
  fromExternal: boolean;
  capacity: Capacity;
}

export interface CapacityChanges {
  new: Capacity[];
  updated: Capacity[];
  deleted: Capacity[];
}

/* ######################## */
// Start new interfaces
/* ######################## */
export interface CapacityTableChanges {
  new: CapacityChange[];
  updated: CapacityChange[];
  deleted: CapacityChange[];
}

export interface CapacityGetBaseRequest {
  from: Date;
  to: Date;
  employeeId: number;
}

export interface CapacityGetTargetHoursRequest extends CapacityGetBaseRequest {
  capacityType: CapacityTypeEnum;
}

export interface CapacityGetRequest extends CapacityGetBaseRequest {
  businessPartners?: number[];
  projects?: number[];
  search?: string;
  page?: number;
  size?: number;
  capacityType: CapacityTypeEnum;
}

export interface CapacityTableData extends Partial<CapacityGetResponse> {
  sortOrder?: number;
  readonly?: boolean;
  value?: number;
  activityId?: number;
}

export interface TimeRangeCapacity {
  comment: string;
  from: string;
  to: string;
  hours: number;
  fromExternal: boolean;
  shadowId?: string;
}

export interface CapacityProject {
  id: number;
  mpNumber: number;
  name: string;
  endDate: string;
  startDate: string;
  projectStatus: string;
}

export interface CapacityGetResponse {
  project: CapacityProject;
  businessPartner: string;
  capacities: TimeRangeCapacity[];
}

export interface PaginationCapacityGetResponse {
  data: CapacityGetResponse[];
  total: number;
  targetHours: Record<string, number> | number;
  totalTargetHours: number;
  dailyTargetHours: number;
  activities: CapacityActivity[];
}

export interface CapacityActivity {
  id: number;
  name: string;
  shortName: string;
  colorCode: string;
}

export interface CapacityChange extends CapacityBase {
  project: CapacityProject;
}

export interface CapacityBase {
  id: number;
  comment: string;
  hours: number;
  from: string;
  to: string;
  fromExternal: boolean;
  originalHours?: number;
  originalComment?: string;
}

export interface CapacityPutRequestCapacity {
  projectId: number;
  activityId?: number;
  from: string;
  to: string;
  hours: number;
  comment?: string;
  fromExternal?: boolean;
  shadowId?: string;
}

export class CapacityPutRequest {
  employeeId: number;
  capacityType: CapacityTypeEnum;
  capacities: CapacityPutRequestCapacity[];
}

export interface CapacityDailyPutRequest {
  id?: number;
  hours: number;
  fromExternal?: boolean;
  comment: string;
  date: string;
}

export interface CapacityDeleteRequest {
  employeeId: number;
  entries: {
    projectId: number;
    from: string;
    to: string;
    shadowId?: string;
  }[];
}

export interface CapacityDeleteResponse {
  success: boolean;
  id?: number;
  message?: any;
}

export interface CapacityGetAggregatesResponse {
  filtered: {
    sum: number;
    entries: {
      from: Date;
      to: Date;
      sum: number;
    }[];
  };
  total: {
    sum: number;
    entries: {
      from: Date;
      to: Date;
      sum: number;
    }[];
  };
}

export interface CapacityGetFiltersResponse {
  projects: {
    id: number;
    mpNumber: number;
    name: string;
  }[];
  businessPartners: {
    id: number;
    name: string;
  }[];
}

export interface CapacityPutResponse {
  id: number;
  project: {
    id: number;
  };
  employee: {
    id: number;
  };
  activityId: number;
  dailyCapacities: {
    id: number;
    date: string;
    hours: number;
    comment: string;
    fromExternal: boolean;
    capacityId: number;
  }[];
  comment: string;
  from: string;
  to: string;
  year: number;
  weekNo: number;
  startDayOfWeek: number;
  endDayOfWeek: number;
  employeeId: number;
  projectId: number;
}

export interface EmployeeProjectCapacities {
  projectCapacities: {
    hours: number;
    week: number;
    year: number;
  }[];
  otherCapacities: {
    hours: number;
    week: number;
    year: number;
  }[];
}
