interface ChildrenProjects {
  id: number;
  hasDetails: boolean;
  mpNumber: boolean;
  mpTitle: string;
  projektName: string;
}

export interface AdjacentInfo {
  childrenProjects: ChildrenProjects[];
  parentProject: number;
}
