export interface AbsencesGetResponse {
  employee: {
    id: number;
    name: string;
    targetHours: number;
    holidays: number;
  };
  weeks: {
    [week: string]: number;
  };
  sum: number;
  sumYear: number;
}

export interface AbsencesGetRequest {
  from: Date;
  to: Date;
  search?: string;
}

export interface AbsencesWeek {
  from: Date;
  to: Date;
  id: string;
}


