import {TechnologiesService} from '@app/shared/services/technologies.service';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Technology} from '@ceres/domain';

@Component({
  selector: 'ceres-cascading-dropdown',
  templateUrl: './cascading-dropdown.component.html',
  styleUrls: ['./cascading-dropdown.component.scss']
})
export class CascadingDropdownComponent implements OnInit {

  @Input() data: Technology[];
  @Input() readonly: boolean;
  @Output() changed = new EventEmitter<any>();

  options: Technology[];
  disabledApply = true;

  levels: number[] = [];
  selected: any[] = [];

  constructor(
    private tecService: TechnologiesService) {
  }

  async ngOnInit() {
    this.options = await this.tecService.getAll();

    this.options.forEach((value) => {

      if (this.levels.indexOf(value.level) < 0) {
        this.levels.push(value.level);
        this.selected[value.level] = null;
      }
    });

    if (!this.data) {
      this.data = [];
    }

    this.data.forEach(item => {
      item['parentName'] = this.tecService.getFirstInfo(this.options, item);
    });

  }

  public select($event, option, level) {
    if ($event.isUserInput) {
      this.selected[level] = option;
      this.selected.forEach((element, index) => {
        if (index > level) {
          this.selected[index] = null;
        }
      });
      this.disabledApply = this.childHasOptions(level + 1);
    }
  }

  public checkParents(option) {
    if (!option.level) {
      return true;
    }

    const selectedLevel = this.selected[(option.level - 1)].id;
    return (option.parent === selectedLevel);
  }

  preventClose(ev: MouseEvent) {
    ev.stopPropagation();
  }

  remove(item): void {
    this.data = this.data.filter(e => item.id !== e.id);
    this.changed.emit(this.data);
  }

  add(): void {
    if (!this.data) {
      this.data = [];
    }
    const item = this.getSelectedItem();
    const filtered = this.data.filter(e => e.id === item.id);
    if (filtered && filtered.length === 0) {
      this.data.push(item);
    }

    for (const idx in this.selected) {
      if (idx) {
        this.selected[idx] = null;
      }
    }

    this.changed.emit(this.data);
  }

  getSelectedItem() {
    for (const item of this.selected.reverse()) {
      if (item) {
        return item;
      }
    }
  }

  private isSelected(level) {
    if (level < 0) {
      return true;
    }

    return this.selected[level] != null;
  }

  private childHasOptions(level?) {
    if (!level) {
      return true;
    }
    const id = this.selected[level - 1].id;
    return this.options.some(item => item.parent === id);
  }
}
