
export interface TemplateSignedURLUploadRequest {
    name: string;
    contentType: string;
}

export interface ConfirmTemplateUpload {
    key: string;
    businessAreaIds: number[];
    convertToPdf: boolean;
}

export interface ConfirmTemplateUploadUpdate {
    id: number;
    oldKey: string;
    newKey: string;
    file: {
        name: string;
        contentType: string;
    }
    convertToPdf: boolean;
}

export interface TemplateKeys {
    documentKey: string;
    pdfKey: string;
}
