import {
  Attribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export const SKILL_CARD_VALUE_ACCESSOR_PROVIDER = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SliderCardComponent),
  multi: true
};

@Component({
  selector: 'ceres-slider-card',
  templateUrl: './slider-card.component.html',
  styleUrls: ['./slider-card.component.scss'],
  providers: [SKILL_CARD_VALUE_ACCESSOR_PROVIDER],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SliderCardComponent<T extends number>
  implements ControlValueAccessor
{
  public onChange!: (value: T) => void;
  public onTouched!: () => void;

  @Input()
  title = '';

  @Input()
  labels: string[] = [];

  value: T | undefined;

  readonly isDisabled: boolean;

  constructor(
    @Attribute('appearance') appearance: 'skill' | undefined,
    @Attribute('allowChange') allowChange: boolean | undefined,
    private readonly cdr: ChangeDetectorRef
  ) {
    this.isDisabled = !allowChange;
  }

  public changeValue(newValue: T) {
    this.value = newValue;
    this.onChange(this.value);
    this.onTouched();
  }

  public writeValue(value: T) {
    this.value = value;

    this.cdr.markForCheck();
  }

  public registerOnChange(fn: (value: T) => void) {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }
}
