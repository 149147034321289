import { Location } from './location';

export interface HolidayByCountryCodeDto {
  [countryCode: string]: string[]
}
export interface HolidayByCountryCode {
  [countryCode: string]: Set<string>
}

export interface Holiday {
  id: number;
  title: string;
  location: Location[];
  date: string;
}

