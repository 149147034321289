export interface ChartDataPoint<TValue = number> {
  name: string;
  value: TValue;
}

export interface ChartDataPointWithExtra<TExtra extends {}, TValue = number>
  extends ChartDataPoint<TValue> {
  extra: TExtra;
}

export interface ChartDataPointPercentageExtra {
  percentage: number;
}

export interface ChartDataPointWithPercentage
  extends ChartDataPointWithExtra<ChartDataPointPercentageExtra> {}
