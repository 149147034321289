export interface Department {
  id: number;
  name: string;
  country: string;
  are: string;
  internalName: string;
  parent: Department | null;
  children: Department[];
  createdManually: boolean;
  servicePricingPackages?: any;
  serviceDepartmentPackages?: any;
  orgId: string;
  orgId2: string;
  weight: number;
  businessArea?: any;
  dc?: string;
}
