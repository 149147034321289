import { Contract } from './contract';
import { Employee } from './employee';

export interface ContractComment {
  id: number;
  created: Date;
  creator: Employee;
  content: string;
  isReviewed: boolean;
  contract: Contract;
}
