<div class="frame" (click)="toggleHide()" [ngClass]="{extendable}">
  <h4 class="title" [ngClass]="{ hiding: hideChildren && extendable }">
    {{ title }}
  </h4>
  <div class="extras" (click)="doNothing($event)">
    <ng-content></ng-content>
  </div>
</div>
<div class="children" [ngClass]="{ hidden: hideChildren && extendable }">
  <ng-content select="ceres-simple-card"></ng-content>
</div>
