import { Component, HostListener } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';

@Component({
  selector: 'ceres-settings-custom-filter-save-dialog',
  templateUrl: './custom-filter-save-dialog.component.html',
  styleUrls: ['./custom-filter-save-dialog.component.scss']
})
export class CustomFilterSaveDialog{
  public name: string

  constructor(
    private dialogRef: MatDialogRef<CustomFilterSaveDialog>,
  ) {
  }

  public cancel() {
    this.dialogRef.close();
  }

  public confirm() {
    this.dialogRef.close(this.name);
  }

  @HostListener('keydown.esc')
  public onEsc() {
    this.dialogRef.close();
  }
}
