import { MaterialMaster } from './material-master';
import { ProjectProfile } from './project-profile';

export interface AccountingInfos {
  id: number;
  orderPosNumber: string;
  orderQuantity: number;
  pricePerUnit: number;
  price: number;
  accountedSAP: number;
  accounted: boolean;
  project: ProjectProfile;
  materialNumber: MaterialMaster;
  hasApprovedElements: boolean;
  hasBillingJournals: boolean;
}
