import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SelectFromTableDialogComponent } from "./select-from-table-dialog.component";
import { TranslocoModule } from "@ngneat/transloco";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { ButtonModule } from "../button/button.module";
import { DialogHeaderModule } from "../dialog-header/dialog-header.module";
import { MatSortModule } from "@angular/material/sort";
import { MatLegacyCheckboxModule as MatCheckboxModule } from "@angular/material/legacy-checkbox";
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';

@NgModule({
  imports: [
    CommonModule,
    TranslocoModule,
    MatTableModule,
    MatDialogModule,
    ButtonModule,
    DialogHeaderModule,
    MatSortModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule
  ],
  declarations: [SelectFromTableDialogComponent],
  exports: [SelectFromTableDialogComponent]
})
export class SelectFromTableDialogModule {}
