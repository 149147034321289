import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CollapseItemComponent } from "./collapse-item.component";

@NgModule({
  imports: [CommonModule],
  declarations: [CollapseItemComponent],
  exports: [CollapseItemComponent]
})
export class CollapseItemModule {}
