<div class="frame">
  <h4 class="title">{{ title }}</h4>
  <mat-slider
    class="slider"
    [max]="labels.length - 1"
    [min]="0"
    [step]="1"
    [value]="value ?? 0"
    (valueChange)="changeValue($event)"
    [disabled]="isDisabled"
  ></mat-slider>
  <div class="description--container">
    <p class="description" *ngFor="let label of labels">
      {{ label }}
    </p>
  </div>
  <div class="extras">
    <ng-content></ng-content>
  </div>
</div>
<div class="children">
  <ng-content select="ceres-slider-card"></ng-content>
</div>
