import { SignedURLDownloadResponse } from '@ceres/domain';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'ceres-doc-preview-dialog',
  templateUrl: './doc-preview-dialog.component.html',
  styleUrls: ['./doc-preview-dialog.component.scss']
})
export class DocPreviewDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { url: Promise<SignedURLDownloadResponse>, provider: string},
    private sanitizer: DomSanitizer
    ) { }

  processing = true;
  url: string;
  safeUrl: any;

  providers = { // providers for document viewing
    microsoft: {
      prefixUrl: 'https://view.officeapps.live.com/op/embed.aspx?src='
    },
    google: {
      prefixUrl: 'https://docs.google.com/gview?url='
    }
  }

  async ngOnInit() {
    const urlObject = await this.data.url;
    this.url = encodeURIComponent(urlObject.url);
    if(this.data.provider == 'microsoft') {
      this.url = this.providers.microsoft.prefixUrl + this.url;
    } else if (this.data.provider == 'google') {
      this.url = this.providers.google.prefixUrl + this.url;
    }
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    this.processing = false;

  }



}
