import { Location } from './location';
import { Technology } from './technology';
import { Employee } from './employee';
import { Activity } from './activity';
import { Program } from './program';
import { BusinessPartner } from './business-partner';
import { MaterialMaster } from './material-master';
import { BusinessArea } from './business-area';
import { PspElement } from './psp-element';
import { ExchangeRate } from './currency';
import { Contract } from './contract';
import { TranslationOption } from './translation-option';
import { ProjectType } from './project-type';
import { ProjectTags } from './project_tags';
import { CustomerPspElement } from './customer-psp-elements';

export interface ProjectProfile {
  id: number;
  copiedFrom?: number; // mpNumber to reference the original project
  classificationGrade: string;
  classificationScore: string;
  conAktiv: any;
  mpNumber: number;
  mpTitle: string;
  hasDetails: boolean;
  projektGruppierung: string;
  allBusinessAreas?: BusinessArea[];
  employees: Employee[];
  fsgGesamtprojekt: number;
  verrechnungsart: string;
  auftragswert: number;
  travelCosts?: number;
  projektName: string;
  projektbeschreibung: string;
  url: string;
  dokumentationUrl: string;
  projektKategorie: string;
  projektMethode: string;
  lighthouseInnovation: boolean;
  productive: boolean;
  gmsServices: string;
  gmsValueAdd: string;
  task: string;
  technologies: Technology[];
  interfaceificationGrade: string;
  interfaceificationScore: number;
  result: string;
  projectLead: Employee;
  abteilungKunde: string;
  validFrom: string;
  validTo: string;
  location: Location;
  EmployeesId: number[];
  TechnologiesId: number[];
  activity: Activity[];
  originalActivity: Activity[];
  isFavorite: boolean;
  originalIsFavorite: boolean;
  isRemovable: boolean;
  favoriteId: number;
  sortOrder: number;
  originalSortOrder: number;
  singleActivity: Activity;
  planpreis: number;
  program: Program[];
  previousProgram: Program[];
  businessPartnerPre: string;
  businessPartnerSur: string;
  projectStatus: string;
  maturityLevel?: TranslationOption;
  plankosten: number;
  istkosten: number;
  externalCosts: number;
  costCenterInternal: string;
  businessPartner: BusinessPartner;
  businessPartnerId: number;
  startDate: string;
  endDate: string;
  bestellwert: number;
  orgID: string;
  bestellnummer: string;
  angebotsnummer: number;
  contractNumber?: string;
  contracts?: Contract[];
  verrechnungsland: string;
  abgerechnet: boolean;
  kundenart: string;
  verrechnungszyklus: string;
  invest: boolean;
  auslandskunde: boolean;
  istpreis: number;
  fsgExtern: number;
  approvalTec: boolean;
  approvalCom: boolean;
  accountingRelevance: number;
  orderQuantity: number;
  orderPosNr: number;
  materialMaster: MaterialMaster;
  istkostenStunden: number;
  istpreisStunden: number;
  istkostenExtern: number;
  istpreisExtern: number;
  leistungsempfaenger: BusinessArea;
  fsgWert: number;
  abgerechneterWert: number;
  spanne: number;
  abzurechnen: number;
  plannedExternalCosts: number;
  parentProjectId?: number;
  isaStatus: number;
  commercialComment: string;
  projectComment: string;
  multipleBusinessPartner: boolean;
  fSGModified: Date;
  folderId: number;
  forecastFY: number;
  forecastCostsOverallFY: number;
  forecastCostsExternalFY: number;

  // calculated values
  plannedCostsHours: number;
  plannedPriceHours: number;
  plannedCostsExternal: number;
  plannedPriceExternal: number;
  accruedCostsExternal: number;
  actualCosts: number;
  actualCostsExternal: number;
  actualCostsHours: number;
  actualPrice: number;
  actualPriceExternal: number;
  actualPriceHours: number;
  actualProfit: number;
  margin: number;
  pocProfit: number;
  pocValue: number;
  proportionalLinearRemainingBudget: number;
  remainingBudget: number;
  valueSettled: number;
  valueToBeSettled: number;
  vsp: number;
  pspElement: PspElement;
  partnerDepthStructure: string;
  plannedHours?: number;
  actualHours?: number;
  currencyCode: string;
  exchangeRate: ExchangeRate;
  projectType?: ProjectType;
  projectTags?: ProjectTags[];
  hasBillingPlan?: boolean;
  sdNumber?: string;
  markedForDeletionTimestamp?: Date;
  updatedBy?: string;
  projectSpaceTitle?: string;
  projectSpaceLink?: string;
  customerPspElement?: CustomerPspElement
}

export interface ProjectNRS extends ProjectProfile {
  accountingElements: BillingJournalEntry[];
  october: number;
  november: number;
  december: number;
  january: number;
  february: number;
  march: number;
  april: number;
  may: number;
  june: number;
  july: number;
  august: number;
  september: number;
  overall: number;
  ssdElement: SSDElement;
}

export interface SSDElement {
  are: string;
  divisionCode: string;
  name: string;
  name2: string;
  shortCompany: string;
}

export interface BillingJournalEntry {
  Id: number;
  CompanyCode: number;
  AdditionalInfoItem1: string; //'DMT-OMW',
  AdditionalInfoHeader1: number; //300288,
  PostingDate: string; // '25.11.2019',
  CompanyIDofTradingPartner: string; // '7411PC',
  CustomerNameAG: string; //'Siemens AG',
  CustomerORGID: string; // 'A4025820',
  CustomerReference: string; //'c=X28221*G=61975500',
  PONumberSupplement: string; //'#',
  SalesOffice: string; // 'A12E',
  SalesGroup: string; // '#',
  ItemCategory: string; //'Z001',
  DocumentCurrency: string; //'EUR',
  SalesOrganization: string; //'A100',
  TermsofPayment: string; //'IC00',
  Material: string; //'KAM1IBETRIEBSUPP',
  BillingDocument: string; // '5001915309',
  SalesDocument: string; //'3001945114',
  YourReference: string; // '#',
  ItemText: string; // 'DMT Verrechnung 2019/11 MP 300288 OMW Support-Bramer-FY20 Offer Email Fr. Bama Sina',
  WBSElement: string; // 'IP-BP015-001',
  ProfitCenter: string; // 'PA10000009',
  AccountingExchangeRate: number; // 1,
  BilledQuantity: number; // 1,
  ExchangeRateForPricing: number; // 1,
  GrossValue: number; // 4205,
  GrossValueInCompCurrency: number; // 4205,
  NetValue: number; // 4205,
  NetValueInCompCurrency: number; // 4205,
  NetValuePerQuantity: number; // 4205,
  Surcharge: number; // 0,
  SurchargeInCompCurrency: number; // 0,
  TaxAmount: number; // 0,
  TaxAmountInCompCurrency: number; // 0,
  TaxPercentage: number; // 0,
  MP: number; // null,
  AdditionalInfoItem: number; // null,
  Title: number; // null,
  ContentTypeId: number; // '0x0100883393398BBCB34E8AB2ED72FB781033',
  ID: number; // 17234,
}

export interface ProjectMetadata {
  choiceValues: ProjectChoiceValues;
  lookupValues: ProjectLookupValues;
  fieldLabels: ProjectFieldLabels;
}

export interface ProjectChoiceValues {
  status: string[];
}

export interface ProjectLookupValues {
  technologies: Technology[];
  location: Location[];
  businessAreas: BusinessArea[];
  projectStatus: TranslationOption[];
  projectTags: ProjectTags[];
}

export type ProjectFieldLabels = {
  [K in keyof ProjectProfile]: string;
};

export interface ProjectNrsFilterValues {
  projektGruppierung: string[];
  'projectLead.name': string[];
  costCenterInternal: string[];
  projectStatus: string[];
  kundenart: string[];
  mpNumber: number[];
  verrechnungsland: string[];
  mpTitle: string[];
  angebotsnummer: string[];
  orgID: string[];
  bestellnummer: string[];
  abteilungKunde: string[];
  verrechnungsart: string[];
  verrechnungszyklus: string[];
  isaStatus: number[];
  'ssdElement.are': string[];
  'ssdElement.divisionCode': string[];
  'ssdElement.name': string[];
  'ssdElement.name2': string[];
  'ssdElement.shortCompany': string[];
}

export interface ProjectAccountingFilterValues {
  mpNumber: number[];
  mpTitle: string[];
  costCenter: string[];
  'projectLead.name': string[];
  projektGruppierung: string[];
  verrechnungsart: string[];
  verrechnungszyklus: string[];
  'businessPartner.fullName': string[];
  pspElement: string[];
  profitCenter: string[];
  abteilungKunde: string[];
  contractNumber: string[];
  materialMaster: string[];
}

export interface ProjectProfileFilterValues {
  mpNumber: number[];
  'businessPartner.fullName': string[];
  'projectLead.name': string[];
  projektGruppierung: string[];
  projectStatus: string[];
  verrechnungsart: string[];
  'leistungsempfaenger.title': string[];
  kundenart: string[];
  isFavorite: string[];
}

export interface ProjectProfilePatchRequest {
  forecastCostsOverallFY?: number;
  forecastCostsExternalFY?: number;
  plannedPrice?: number;
  externalCosts?: number;
  plannedCosts?: number;
  contractVolume?: number;
  sdNumber?: string;
  organizationId?: string;
}

export interface PreliminaryProject {
  name: string;
  referenceProjectId: number;
}
