export interface ServiceProduct {
  id: number;
  status?: string;
  no?: number;
  gbsServiceId?: string;
  gbsFunctionBL?: string;
  gbsFunctionSL?: string;
  businessSegmentSubsegment?: string;
  serviceType?: string;
  serviceLineItem?: string;
  pck?: string;
  dsPckValidation?: string;
  productName?: string;
  productInvoiceName?: string;
  mlfb?: string;
  regionScope?: string;
  serviceDesc?: string;
  serviceProcesses?: string;
  scopeExclusions?: string;
  serviceOwnerEmail?: string;
  serviceMeteringMethod?: string;
  businessVolumeIndicatorName?: string;
  businessVolumeIndicatorSpecification?: string;
  customerFunction?: string;
  customerType?: string;
  personalData?: string;
  esn?: string;
  serviceRelevanceFocus4?: string;
  createdAt?: Date;
  lastUpdatedAt?: Date;
}

export interface ServicesProductsResponse {
  data: ServiceProduct[],
  date: { start: string, end: string },
  total: number
}

export interface GetServiceProductsRequest {
  offset: number;
  search: string;
}

