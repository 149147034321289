<mat-form-field class="mat-custom w-100" [ngClass]="{ 'custom-input-invalid': invalid }" appearance="fill">
  <mat-label>{{placeholder}}</mat-label>
  <input matInput
         [required]="required"
         [value]="valueTransformer(value)"
         readonly>
  <ng-template [ngIf]="!isDisabled" matSuffix>
    <mat-icon *ngIf="!value; else other" (click)="openSelection($event)"
              [ngClass]="{ 'invalid': invalid }" class="mat-custom">edit</mat-icon>
    <ng-template #other>
      <mat-icon (click)="removeSelected($event)" class="mat-custom">delete</mat-icon>
    </ng-template>
  </ng-template>
</mat-form-field>
