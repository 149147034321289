import { Classification as CeresClassification } from '@ceres/domain';

export class Classification implements CeresClassification {
    
    id: number;
    questionId: number;
    value: number;
    programId: number;
    originalValue: number;
    comment: string;
    originalComment: string;


    constructor(object?: Partial<Classification>) {
        if (object) {
            Object.assign(this, object);
        }
    }

}
