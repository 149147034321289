import { BusinessArea, BusinessAreaDto } from './business-area';
import { Location } from './location';
import { EmployeeContractType } from './employee-contract-type';
import { Role } from './role';
import { Portfolio } from './portfolio';
import { Capacity } from './capacity';
import { Activity } from './activity';
import { Favorite } from './favorite';
import { Workload } from './workload';
import { ProgramFavorite } from './program-favorite';
import { IUser } from './user';
import { Skill } from './skill';
import { PspElement } from './psp-element';

export interface Employee {
  id: number;
  email: string;
  gid: string;
  department: string;
  country: string;
  corporateName: string;
  are: string;
  businessArea: BusinessAreaDto;
  allBusinessAreas: BusinessAreaDto[];
  FK: boolean;
  KFM: boolean;
  contractType: EmployeeContractType;
  location: Location;
  //role: Role;
  isActive: boolean;
  portfolio: Portfolio;
  sollstunden: number;
  urlaubstage: number;
  name: string;
  nameShort: string;
  //TODO remove after user implementation
  assignedEmployees: Employee[];
  //TODO remove after user implementation
  supervisors: Employee[];
  assignedUsers: IUser[];
  supervisorUsers: IUser[];
  capacity: Capacity[];
  // ToDo: number?
  workload: Workload[] | number;
  defaultActivity: Activity;
  activities: Activity[];
  previouslyAssignedActivities: Activity[];
  assignedActivities: Activity[];
  favorite: Favorite;
  programFavorite: ProgramFavorite;
  lastUpdateBySCD: Date;
  user?: IUser;
  skills: EmployeeSkill[];
  internalPspElement?: PspElement
}

export interface EmployeeChoiceValues extends _.Dictionary<string[]> {
  status: string[];
}

export interface EmployeeLookupValues  {
  contractType: EmployeeContractType[];
  role: Role[];
  location: Location[];
  portfolio: Portfolio[];
  activity: Activity[];
  businessArea: BusinessArea[];
  internalPspElements: PspElement[]
}

export type EmployeeFieldLabels =
  | _.Dictionary<string[]>
  | (_.Dictionary<string> & {
      [K in keyof Employee]: string;
    });

export interface EmployeeMetadata {
  choiceValues?: EmployeeChoiceValues;
  lookupValues?: EmployeeLookupValues;
  fieldLabels?: EmployeeFieldLabels;
}

export interface EmployeeFilterValues {
  name: string[];
  'contractType.translationKey': string[];
  'role.translationKey': string[];
  'allBusinessAreas.name': string[];
  'businessAreas.name': string[];
  'squads.name': string[];
  'portfolio.title': string[];
  updatedByEmail: string[];
}

export interface GetProjectsByFilterWithMissingOfferResponse {
  id: number;
  offerNumber: string;
  offerId: number;
  offerTitle: string;
  // quotationNo: string;
  mpNumber: string;
  mpTitle: string;
  businessPartner: {
    lastName: string;
    firstName: string;
    email: string;
    salutation: string;
    gid: string;
    department: string;
  };
  projectLead: {
    name: string;
    email: string;
    gid: string;
  };
  orgId: string;
  sdNumber: string;
  orderNumber: string;
}

export interface EmployeeSkillMatrix {
  id: Employee['id'];
  name: Employee['name'];
  department: Employee['department'];
  skills: Employee['skills'];
  activities: Employee['activities'];
}

export interface EmployeeSkill {
  skill: Skill;
  points: number;
}
