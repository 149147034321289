import type { Activity } from './activity';

export interface Skill {
  id: number;
  name: string;
  category?: SkillCategory;
}

export interface SkillCategory {
  id: number;
  name: string;
  skillSet?: SkillSet;
  skills?: Skill[];
}

export interface SkillSet {
  id: number;
  name: string;
  skillCategories?: SkillCategory[];
  activities?: Activity[];
}
