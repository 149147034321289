import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { SignedURLDownloadResponse } from '@ceres/domain';

@Component({
  selector: 'ceres-pdf-viewer-dialog',
  templateUrl: './pdf-viewer-dialog.component.html',
  styleUrls: ['./pdf-viewer-dialog.component.scss']
})
export class PdfViewerDialogComponent implements OnInit {

  pdfSrc: string;
  processing = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { url: Promise<SignedURLDownloadResponse> },
    public dialogRef: MatDialogRef<PdfViewerDialogComponent>
    ) { }

  async ngOnInit() {
    this.pdfSrc = (await this.data.url).url;
  }

  close() {
    this.dialogRef.close();
  }

  afterLoadComplete() {
    this.processing = false;
  }

}
