import { OfferTemplateElement } from './offer-templates';
import { BusinessArea } from './business-area';
import { BusinessPartner } from './business-partner';
import { ContractComment } from './contract-comment';
import { Folder } from './folder';
import { Employee } from './employee';
import { ProjectProfile } from './project-profile';
import { TranslationOption, TranslationOptionContractCurrency } from './translation-option';

export enum ContractStatusType {
  REVIEW = 'Review',
  TECH = 'Ready for Signature - Tech',
  COMMERCIAL = 'Ready for Signature - Commercial',
  INCOMPLETE = 'Incomplete',
  SIGNED = 'Signed',
  ORDERED = 'Ordered',
  EXPIRED = 'Expired',
  TERMINATED = 'Terminated'
}

export interface ContractQuery {
  date?: string;
  dateEnd?: string;

  email?: string;
  area?: string;
  status?: ContractStatusType;
  search?: string;
  mp?: number;
  projectNumber?: number;
  'serviceProvider.id'?: number;
  'contractStatus.status'?: string;
}

export interface Contract {
  id: number;
  // contractIdentifier: string;
  businessArea: BusinessArea;
  mpNumber: string;
  contractManager: string;
  contractManagerEmail: string;
  // serviceProviderContact: string;
  // serviceProviderContactEmail: string;
  // serviceProviderUnit: string;
  // serviceProviderARE: string;
  // serviceProviderCorporateName: string;
  // serviceProviderCountry: string;
  // serviceProvider: string;
  serviceProvider: Employee;
  title: string;
  contractDescription: string;
  contractType: TranslationOption;
  contractStatus: ContractStatus;
  contractVolume: number;
  startdate: string;
  expiryVersion: string;
  expiryDate: string | Date;
  contractDuration: number;
  // terminationPeriod: number;
  outsourcing: boolean;
  regulatoryRequirement: string;
  comment?: ContractComment[];
  commentString?: string;
  // reviewDate: Date;
  contractCurrency: TranslationOptionContractCurrency;
  isaBusinessContact: string;
  isaTechnicalContact: string;
  numberOfDocuments: number;
  modified: string;
  created: string;
  author: { title: string };
  isReviewed: boolean;
  isISAImported: boolean;
  quotationNo: string;
  isaIncluded: boolean;
  customer: BusinessPartner;
  endDate: Date;
  startDate: Date;
  orderValue: number;
  mpTitle: string;
  folderId: number;
  folder?: Folder;
  // New data
  contractNumber: string; // e.g. 2020-08-5-YL-03
  // New Offer Accounting Info etc.
  kundenart?: string
  verrechnungsart?: string;
  verrechnungsland?: string;
  verrechnungszyklus?: string;
  auslandskunde?: string;
  approvalTec?: string;
  invest?: string;
  approvalCom?: string;
  fileName?: string;
  offerTemplate?: OfferTemplateElement
  calculationId?: number;
  projectProfile?: ProjectProfile;
}

export interface ContractFilterValues {
  title: string[];
  'contractType.translationKey': string[];
  mpNumber: number[];
  quotationNo: string[];
  'businessArea.title': string[];
  'serviceProvider.name': string[];
  'serviceProvider.id': number[];
  'customer.fullName': string[];
  'customer.department': string[];
  'contractStatus.translationKey': string[];
  isaIncluded: boolean[];
}

export interface ContractStatus {
  status: ContractStatusType;
  translationKey: string;
  rankOrder: number;
  id: number;
}
