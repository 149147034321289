<!-- FILTER ELEMENT -->
<div class="filter-spacing">
  <ng-container>
    <span class="filter-title">{{ name }}</span>
  </ng-container>

  <span class="pointer" (click)="visible = true" [matMenuTriggerFor]="filterMenu">
    <ng-container *ngTemplateOutlet="filterIcon"></ng-container>
</span>

  <!-- FILTER ICON -->
  <ng-template #filterIcon>
    <ng-container *ngIf="!visible && !isApplied">
        <span #iconClosed>
            <ng-content select="[icon-closed]"></ng-content>
        </span>
      <i *ngIf="iconClosed.childNodes.length === 0" class="material-icons">keyboard_arrow_down</i>
    </ng-container>

    <ng-container *ngIf="visible && !isApplied">
        <span #iconOpened>
            <ng-content select="[icon-opened]"></ng-content>
        </span>
      <i *ngIf="iconOpened.childNodes.length === 0" class="material-icons">keyboard_arrow_up</i>
    </ng-container>

    <ng-container *ngIf="isApplied">
        <span #iconApplied>
            <ng-content select="[icon-applied]"></ng-content>
        </span>
      <!-- <i *ngIf="iconApplied.childNodes.length === 0" class="icon--filter"></i> -->
      <i *ngIf="iconApplied.childNodes.length === 0" class="material-icons">filter_list</i>
    </ng-container>
  </ng-template>
</div>


<!-- FILTER COMPONENT -->
<mat-menu #filterMenu="matMenu" [xPosition]="xPosition" (closed)="visible = false">
    <div class="pwo-filter" [class.pwo-styles]="hasStyles" (click)="$event.stopPropagation();" *ngIf="visible">
        <!-- only render component if shown for better performance -->

        <lib-filter *ngIf="!isTabbed" [filter]="filter" [filterService]="filterService" (close)="close()"></lib-filter>
        <mat-tab-group *ngIf="isTabbed">
            <mat-tab *ngFor="let f of filter" [label]="transform(f.key)">
                <lib-filter [filter]="f" [filterService]="filterService" (close)="close()"></lib-filter>
            </mat-tab>
        </mat-tab-group>
    </div>
</mat-menu>
