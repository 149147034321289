import { Portfolio } from './portfolio';
import { BusinessArea } from './business-area';
import { Location } from './location';

export interface SettingsMetadata {
  lookupValues: SettingsLookupValues;
}

export interface SettingsLookupValues {
  portfolio: Portfolio[];
  businessArea: BusinessArea[];
  location: Location[];
}
