<div class="container" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div class="row">
      <h5 class="content-container-title mb-3">{{
        'my-ceres.settings.custom-filter.save-custom-filter' | transloco }}</h5>
  </div>
  <div class="row">
      <mat-form-field class="col-12 mat-custom p-0" appearance="fill">
          <mat-label>{{ 'my-ceres.settings.custom-filter.filter-name' | transloco }}</mat-label>
          <input matInput #filterName="ngModel" [(ngModel)]="name" required placeholder="{{ 'my-ceres.settings.custom-filter.filter-name-required' | transloco }}"/>
          <mat-error *ngIf="filterName.errors?.['required']">
            {{ 'my-ceres.settings.custom-filter.filter-name-required' | transloco }}
        </mat-error>
      </mat-form-field>
  </div>
  <div class="d-flex gap-10 justify-content-end align-items-center">
    <ceres-button (click)="confirm()" [icon]="'check-mark'" [disabled]="filterName.errors?.['required']">
        {{'shared.buttons.apply' | transloco }}
    </ceres-button>
    <ceres-button (click)="cancel()" [icon]="'close'" [style]="'red'">
        {{'shared.buttons.cancel' | transloco }}
    </ceres-button>
</div>
</div>
