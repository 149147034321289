import { Pipe, PipeTransform } from '@angular/core';
import { Employee } from '@ceres/domain';

@Pipe({
  name: 'extNames'
})
export class ExtNamesPipe implements PipeTransform {
  transform(value: string, employee?: Employee): any {
    if (
      employee &&
      employee.email &&
      employee.email.includes('ext') &&
      !employee.email.includes('siemens-healthineers')
    ) {
      return 'Ressource Extern ' + employee.id;
    }
    return value;
  }
}
