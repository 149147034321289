<div class="d-flex flex-wrap gap-3 align-items-center">
  <mat-form-field>
    <mat-label>{{
      'my-ceres.settings.custom-filter.select-custom-filter' | transloco
    }}</mat-label>
    <mat-select [(ngModel)]="selectedCustomFilter">
      <mat-option
        (click)="applySectionFilters(customFilterOption)"
        *ngFor="let customFilterOption of customFilterOptions"
        [value]="customFilterOption"
        >{{ customFilterOption.filterTitle }}
      </mat-option>
      <mat-option
        *ngIf="!!customFilterOptions && customFilterOptions?.length === 0"
        disabled
        >{{ 'my-ceres.settings.custom-filter.no-custom-filter' | transloco }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <ceres-button (click)="saveCustomFilter()">
    <mat-icon *ngIf="!isLoading">save</mat-icon>
    <ceres-loading
      *ngIf="isLoading"
      [diameterSize]="24"
      [insideButton]="true"
      [noMessage]="true"
    ></ceres-loading>
    <span>{{
      'my-ceres.settings.custom-filter.save-custom-filter-button' | transloco
    }}</span>
  </ceres-button>
  <ceres-button (click)="resetFilters()" [style]="'grey'">
    <mat-icon>refresh</mat-icon>
    <span>{{
      'my-ceres.settings.custom-filter.reset-custom-filter-button' | transloco
    }}</span>
  </ceres-button>
</div>
