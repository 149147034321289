<div
  data-testid="collapse-item-toggle"
  class="container-main"
  [ngClass]="{ open: show }"
  (click)="toggle()"
>
  <ng-content></ng-content>
</div>
<div *ngIf="show" class="container-collapse">
  <ng-content select="[collapse]"></ng-content>
</div>
