import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ceres-beta',
  templateUrl: './beta.component.html',
  styleUrls: ['./beta.component.scss']
})
export class BetaComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
