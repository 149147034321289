<ng-container *ngIf="options">
  <div class="multiple-chip">
    <mat-form-field class="pwo-no-underline">
      <mat-chip-list [(ngModel)]='data'>
        <mat-chip (removed)="remove(item)" *ngFor="let item of data" [removable]="!readonly">
          {{ item.parentName + '/' + item.title }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <mat-chip *ngIf="!readonly" [matMenuTriggerFor]="menu" class="highlighted pwo-btn"
        >
          <mat-icon>add</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </mat-form-field>
  </div>

  <mat-menu #menu="matMenu" [overlapTrigger]="false">
    <div>
      <ng-container *ngFor="let level of levels">
        <mat-form-field (click)="preventClose($event)" *ngIf="isSelected(level-1) && childHasOptions(level)">
          <mat-select placeholder="{{ 'shared.select.please-select-placeholder' | transloco }}">
            <ng-container *ngFor="let option of options">
              <mat-option (onSelectionChange)="select($event, option, level)"
                          *ngIf="option.level == level && checkParents(option)"
                          [value]="option">
                {{ option.title }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </ng-container>

      <div class="filter-apply">
        <button (click)="add()" [disabled]="disabledApply" color="accent" mat-raised-button
                type="button">{{ 'shared.buttons.add' | transloco }}</button>
      </div>
    </div>
  </mat-menu>
</ng-container>


