import { BusinessAreaWithoutConvert } from './business-area';

export enum ProjectValidationError {
  MissingOrderValue = 'MissingOrderValue',
  PriceHigherThanOrderValue = 'PriceHigherThanOrderValue',
  PriceHigherThanPartialOrderValue = 'PriceHigherThanPartialOrderValue',
  FixedPriceWithoutContractVolume = 'FixedPriceWithoutContractVolume',
  EndDateOutOfFiscalYear = 'EndDateOutOfFiscalYear',
  EndDateBeforeStartDate = 'EndDateBeforeStartDate',
  ExternalProjectWithBeneficiary = 'ExternalProjectWithBeneficiary',
  PocUpdateOverdue = 'PocUpdateOverdue',
  PocNeverUpdated = 'PocNeverUpdated',
  TMOrderValueMissing = 'TMOrderValueMissing',
  ZeroFixedPriceForCustomerFromOtherTeam = 'ZeroFixedPriceForCustomerFromOtherTeam',
  BeneficiaryBusinessPartnerMismatch = 'BeneficiaryBusinessPartnerMismatch'
}

export enum BopValidationError {
  StartInPast = 'StartInPast'
}

export interface DataQualityIssue<TValidationError> {
  validationErrors: TValidationError[];
}

export interface ProjectDataQualityIssue
  extends DataQualityIssue<ProjectValidationError> {
  projectProfileId: number;
  projectName: string;
  mpNumber: number;
  projectGrouping: string;
  projectLeadName: string;
  projectStartDate: Date;
  projectEndDate: Date;
}

export interface BopDataQualityIssue
  extends DataQualityIssue<BopValidationError> {
  bopId: number;
  bopNumber: string;
  bopTitle: string;
  bopLeaderName: string;
  businessArea?: BusinessAreaWithoutConvert;
  plannedProjectStart?: Date;
  plannedProjectEnd?: Date;
}

export interface ProjectDataQualityIssueFilterValues {
  projectLeadName: string[];
  mpNumber: number[];
  projectName: string[];
}

export interface BopDataQualityIssueFilterValues {
  bopNumber: string[];
  bopTitle: string[];
  bopLeaderName: string[];
}
