export enum ChargingModeE {
  EFFORT_BASED = 'EFFORT_BASED',
  FIXED_PRICE = 'FIXED_PRICE'
}

export interface ExternalProjectData {
  project: ExternalProjectSearchResponse,
  workloads: ExternalProjectWorkloadSchemaResponse[]
}

export interface ExternalProjectWorkloadSchemaResponse {
  employeeGid: string;
  employeeCostCenter: string;
  allocatedHours: number;
  sapNumber: string;
  elementCostCenter: string;
  dueDay: string;
  duePeriod: number;
  bookingPeriod: number;
  fpMd: string;
  sourceId: string;
  lastchange: string;
}

//ExternalTimesheetResponse[] for project named query schema
export interface ExternalProjectResponse {
  Id: number;
  Start: string;
  End: string;
  SapNumber: string;
  Title: string;
  tasks: Task[];
  Costs: Costs;
  financing: Financing;
  facets: { namespace: string; name: string };
  Note: Note;
  ChargingMode: string; //ChargingModeE?
  subsidies: Subsidies;
  contractType: Entry;
  FinancialEnd: Date;
  additionalFields: AdditionalFields[];
  alias: string;
  businessType: Entry;
  category: Entry;
  charging: Charging;
  client: string;
  clientType: Entry;
  Comments: Comment[];
  Contractor: BusinessPartner; //
  cst: Entry;
  Currency: string;
  Customer: BusinessPartner;
  cyberSecurity: CyberSecurity;
  ecc: Ecc;
  fundingSource: string;
  MaturityLevel: string;
  messages: Message[];
  pmAtSiemensCategory: string;
  practice: Entry;
  probability: number;
  projectType: Entry;
  qualiTrafficLight: string;
  Region: string;
  remoteLinks: RemoteLinks;
  Status: Entry;
  tags: Tag[];
  verticals: Entry;
  cct: Entry;
}

interface Financing {
  bmc: Cost;
  cmc: Cost;
  ir: Cost;
  sg: Cost;
  financingMode: string;
}

interface Costs {
  directCosts: Cost;
  LaborCosts: Cost;
  hours: Cost;
  directCostItems: DirectCostItems;
  internalCosts: Cost;
}

interface Cost {
  Value: CostDetail;
  material: string;
}

interface DirectCostItems {
  internalCosts: Cost;
  universities: Cost;
  travel: Cost;
  material: Cost;
  extSubcontr: Cost;
  temporaryLabor: Cost;
  other: Cost;
  infrastructure: Cost;
  licenses: Cost;
  hardware: Cost;
  training: Cost;
  laborCostEAL: Cost;
  laborCostSubcontr: Cost;
}

interface CostDetail {
  items?: CostDetail[];
  actual: number;
  obligo: number;
  Type: string; //maybe enum
  unit: number;
  value: number;
  OrderEntry: number;
}

interface Task {
  title: string;
  sourceId: string;
  startDate: Date;
  endDate: Date;
  sapNumber: string;
  activityTypes: string[];
  subTasks: string[];
  costCenter: string;
  planable: boolean;
  planValues: PlanValue[];
}

interface PlanValue {
  startDate: Date;
  endDate: Date;
  planValue: number;
}

interface Subsidies {
  contractNumber: string;
  organization: string;
  acronym: string;
  fundingMode: string;
  fundingRate: number;
}

interface Entry {
  Label: string;
  module: string;
  value: string;
}

interface Charging {
  chargingOnHourlyBasis: boolean;
  markups: {
    additionalProp1: number;
    additionalProp2: number;
    additionalProp3: number;
  };
  orgId: string;
  purchaseOrderNo: string;
  sdSalesOrder: {
    additionalProp1: string;
    additionalProp2: string;
    additionalProp3: string;
  };
  siemensHPos: string;
  markupDirectCosts: {
    additionalProp1: number;
    additionalProp2: number;
    additionalProp3: number;
  };
}

interface Comment {
  Type: string;
  Value: string;
}

interface AdditionalFields {
  name: string;
  value: Record<string, unknown>;
}

interface Employee {
  //address: string;
  City: string;
  CommonName: string;
  Company: string;
  Country: string;
  Department: string;
  Gid: string;
  GivenName: string;
  Mail: string;
  //postalCode: string;
  Surname: string;
  TelephoneNumber: string;
}

interface OrgUnit {
  orgUnit: string;
  fullName: string;
  level: number;
  shortName: string;
}

interface BusinessPartner {
  BusinessAdministration: Employee;
  chargingRateCostCenter: string;
  consultingGroup: Entry;
  costCenter: string;
  countries: string;
  country: string;
  description: string;
  division: string;
  id: number;
  LineManager: Employee;
  name: string;
  nearshoreServiceManager: Employee;
  orderManager: Employee;
  orgUnit: OrgUnit;
  ProjectManager: Employee;
  qualityManager: Employee;
  serviceLevelManager: Employee;
  serviceManager: Employee;
}

interface AdditionalProp {
  id: string;
  remoteBaseURL: string;
  remoteId: string;
  remoteURL: string;
  type: string;
}

interface RemoteLinks {
  additionalProp1: AdditionalProp;
  additionalProp2: AdditionalProp;
  additionalProp3: AdditionalProp;
}

interface Tag {
  namespace: string;
  value: string;
}

interface CyberSecurity {
  acpAssetId: string;
  acpAssetIdDescription: string;
  acpAssetIdVisible: boolean;
  acpAssetIdEntry: boolean;
  acpLevel: number;
  acpLevelAvailability: number;
  acpLevelConfidentiality: number;
  acpLevelIntegrity: number;
}

interface Ecc {
  awvnumber: string;
  awv1: string;
  awvLabel: string;
}

interface Message {
  type: string;
  text: string;
}

interface Note {
  type: string;
  richtText: string;
  PlainText: string;
}

export interface ExternalProjectSearchResponse {
  Project: ExternalProjectResponse;
}
