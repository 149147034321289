import { BusinessArea } from './business-area';
import { Portfolio } from './portfolio';

export interface News {
  id: number;
  title: string;
  content: string;
  collapsed: boolean;
  critical: boolean;
  validTo: Date;
  priority: number;
  target: string;
  targetGroup: Portfolio;
  targetTeam: BusinessArea;
}

