import { Component, Input, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { Filter } from '../../../lib/filters';
import { MatLegacyMenuTrigger as MatMenuTrigger, MatLegacyMenu as MatMenu } from '@angular/material/legacy-menu';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'standalone-filter',
  templateUrl: './standalone-filter.component.html',
  styleUrls: ['./standalone-filter.component.scss']
})
export class StandaloneFilterComponent {

  @ViewChild(MatMenuTrigger) triggerMenu: MatMenuTrigger;

  @Input() align: string;
  @Input() filter: Filter | Filter[];
  @Input() filterService: Filter;
  @Input() name: string;

  @Output() toggle = new EventEmitter();

  @ViewChild('filterMenu') menuRef: MatMenu;

  visible = false;
  isDisabled = false;
  isAnchored = false;

  hasStyles = false;

  @Input() transform = (key: string) => key;

  get xPosition() {
    return this.align === 'right' ? 'before' : 'after';
  }

  get isTabbed() {
    return Array.isArray(this.filter);
  }

  get isApplied() {
    if (Array.isArray(this.filter)) {
      return this.filter.some(f => f && f.isApplied);
    } else {
      return this.filter && this.filter.isApplied;
    }
  }

  constructor(element: ElementRef) {
    this.hasStyles = element.nativeElement.hasAttribute('pwo');
  }

  open() {
    this.toggle.next(true);
  }

  close() {
    if (this.isAnchored) {
      this.toggle.next(false);
    }
    if (this.triggerMenu) {
      this.triggerMenu.closeMenu();
    }
  }
}
