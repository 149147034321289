export interface CostReportManual {
  markup: number;
  fiscalYear: number;
  period: number;
  costElementDescr: string;
  nameOfsettingAccount: string;
  valueRepCur: number;
  purchaseOrderText: string;
  mpNumber: number;
  postingDate: Date;
}
