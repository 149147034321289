import { Component, OnInit, Input } from '@angular/core';
import { Upload } from '@app/shared/models/upload';

@Component({
  selector: 'ceres-upload-progress',
  templateUrl: './upload-progress.component.html',
  styleUrls: ['./upload-progress.component.scss']
})
export class UploadProgressComponent implements OnInit {
  @Input() fileUploads: Upload;

  constructor() {}

  ngOnInit() {}
}
