import { Injectable } from '@angular/core';
import { CommonPagination, CommonLoading } from '@ceres/frontend-helper';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  ProjectProfile,
  ProjectProfileFilterValues,
  PaginationResponse
} from '@ceres/domain';
import { Filter, MultiValueFilter } from '@ceres/filter';
import { HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { AppUserService } from "@ceres/shared/services";
import { ImpersonatedHttpClient } from "@ceres/shared/services";

@Injectable({
  providedIn: 'root'
})
export class ProjectSelectionService extends CommonPagination(
  { index: 0, size: 15 },
  CommonLoading(Object)
) {
  private _projectProfiles: BehaviorSubject<
    ProjectProfile[]
  > = new BehaviorSubject<ProjectProfile[]>([]);
  public projectProfiles$: Observable<
    ProjectProfile[]
  > = this._projectProfiles.asObservable();

  private _filterValues: BehaviorSubject<
    ProjectProfileFilterValues
  > = new BehaviorSubject<ProjectProfileFilterValues>(null);
  public filterValues$: Observable<
    ProjectProfileFilterValues
  > = this._filterValues.asObservable();

  private _appliedFilters: Filter[] = [];

  private _search = '';

  constructor(private http: ImpersonatedHttpClient, private appUserService: AppUserService) {
    super();
  }

  public applyFilters(appliedFilters: Filter[], filterMarkedForDeletion?: boolean) {
    this._appliedFilters = appliedFilters;
    this.getAll(filterMarkedForDeletion);
  }

  public set appliedFilters(appliedFilters: Filter[]) {
    this._appliedFilters = appliedFilters;
    this.getAll();
  }

  public get appliedFilters(): Filter[] {
    return this._appliedFilters;
  }

  public applySearch(search: string, filterMarkedForDeletion?: boolean) {
    this._search = search;
    this.pageIndex = 0;
    this.getAll(filterMarkedForDeletion);
  }

  public set search(search: string) {
    this._search = search;
    this.pageIndex = 0;
    this.getAll();
  }

  public get search(): string {
    return this._search;
  }

  public getAllProjectProfiles(): ProjectProfile[] {
    return this._projectProfiles.getValue();
  }

  public getAll(filterMarkedForDeletion?: boolean) {
    this.loading = true;
    let params = this.getPaginationParams();
    const filters: any[] = [];
    this.appliedFilters.map(filter => {
      const values = (filter as MultiValueFilter).selected;
      filters.push({
        key: filter.key,
        type: 'multivalue',
        values
      });
    });
    if (this.search && this.search !== '') {
      const searchFields = [
        'mpNumber::text',
        'mpTitle',
        'projektGruppierung',
        'projectLead.name',
        'businessPartner.fullName'
      ];
      searchFields.map(field =>
        filters.push({
          key: field,
          type: 'search',
          term: this.search
        })
      );
    }
    if (filters.length > 0) {
      params = params.append('filters', JSON.stringify(filters));
    }
    let url = '/projects/profile';
    if (
      this.appUserService.isAdmin() ||
      this.appUserService.isSupport() ||
      this.appUserService.isGroupLeader() ||
      this.appUserService.isLearningArchitect() ||
      this.appUserService.isTeamLeaderCharging() ||
      this.appUserService.isTeamLeader()
    ) {
      const employee = this.appUserService.loggedInUser$.getValue().details;
      const projectGrouping = employee.businessArea.title;
      const projectLeadName = employee.name;
      url = `/projects/employees/${employee.id}`;
      params = params.append('projectGrouping', projectGrouping);
      params = params.append('projectLeadName', projectLeadName);
      if(filterMarkedForDeletion === true){
        params = params.append('filterMarkedForDeletion', true);
      }
    }
    this.http
      .get<PaginationResponse<ProjectProfile>>(
        `${environment.edgeService}${url}`,
        {
          params
        }
      )
      .pipe(catchError(err => of({ data: [], total: 0 })))
      .subscribe(({ data, total }) => {
        this._total.next(total);
        this._projectProfiles.next(data);
        this.loading = false;
      });
  }

  public getFilterValues(filterMarkedForDeletion?: boolean) {
    const employee = this.appUserService.loggedInUser$.getValue().details;
    const projectGrouping = employee.businessArea.title;
    const projectLeadName = employee.name;
    let params = new HttpParams();
    params = params.append('projectGrouping', projectGrouping);
    params = params.append('projectLeadName', projectLeadName);
    if (filterMarkedForDeletion === true){
      params = params.append('filterMarkedForDeletion', false);
    }
    this.http
      .get<ProjectProfileFilterValues>(
        `${environment.edgeService}/projects/employees/${employee.id}/filterValues`,
        { params }
      )
      .subscribe(filterValues => this._filterValues.next(filterValues));
  }
}
