import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'ceres-discard-dialog',
  templateUrl: './discard-dialog.component.html',
  styleUrls: ['./discard-dialog.component.scss']
})
export class DiscardDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: { text: string, header: string, disableClose: boolean, confirmButtonText: string },
              private matDialogRef: MatDialogRef<DiscardDialogComponent>) {
  }

  ngOnInit() {
  }

  public cancel() {
    this.matDialogRef.close();
  }

  public confirm() {
    this.matDialogRef.close(true);
  }

  @HostListener('keydown.esc')
  public onEsc() {
    this.matDialogRef.close();
  }

}
