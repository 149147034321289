export const environment = {
  production: true,
  edgeService: 'https://api.dev.cys.ceres.siemens.cloud',
  scd_url: 'https://api.dev.scd.suites.siemens.cloud',
  siteCollection: '/test/10000120', // DEV
  languageAssetsPath: './assets/language/',
  tracking: false,
  whitelist: [
    'dt5zv31q0e.execute-api.eu-central-1.amazonaws.com',
    'api.dev.cys.ceres.siemens.cloud'
  ],
  authentication: {
    unauthorizedRoute: '/unauthorized',
    redirectUrl: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    clientId: '2485rskcbn6lcsp53ijc03063p',
    authority:
      'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_3plO1v5sg',
    authWellknownEndpointUrl:
      'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_3plO1v5sg/.well-known/openid-configuration',
    scope: 'openid profile email',
    responseType: 'code',
    silentRenew: true,
    useRefreshToken: true,
    autoUserInfo: true,
    renewTimeBeforeTokenExpiresInSeconds: 300,
    renewUserInfoAfterTokenRenew: true,
    ignoreNonceAfterRefresh: true
  },
  apiKey: 'UB2dWKgyYK3Us9VClqoeL1xgMvGOWaBJ8bmqUgw2',
  tenant: 'DEV - CYS',
  guestAccess: false,
  links: {
    documentOfferTemplate: '',
    documentOfferTemplateEN: ''
  },
  junoApp: 'https://juno.suites.siemens.cloud',
  junoApiURI: 'https://api.mail.wcs-apps.siemens.cloud/v2.0',
  junoAppId: '73a1c4a89ae54505824b6f0f4fcc78b3'
};
