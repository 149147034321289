import { ApiWorkload } from './workload';
import { ApiPortfolio } from './portfolio';

export interface ApiHighlight {
  Id: number;
  Portfolio: ApiPortfolio;
  PortfolioId: number;
  BusinessResults: string;
  Productivity: string;
  Strategy: string;
  Acquisition: string;
  EmployeesFixed: number;
  EmployeesLeasing: number;
  EmployeesIntern: number;
  LESalesReal: number;
  LESalesPeriod: number;
  LECostReal: number;
  LECostPeriod: number;
  AccountingMonth: number;
  BOPVolume: number;
  AEActual: number;
  AEbereinigt: number;

  Workload: ApiWorkload;
  LESalesConservative: number;
  LECostConservative: number;
  LESalesOptimistic: number;
  LECostOptimistic: number;
  ChargingQuote: number;
}
