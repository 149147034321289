import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {CapacityTypeEnum} from '@ceres/domain';
import {TranslocoService} from '@ngneat/transloco';
import {addMonths, addYears, subMonths, subYears} from 'date-fns';

@Component({
  selector: 'ceres-month-selector',
  templateUrl: './month-selector.component.html',
  styleUrls: ['./month-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MonthSelectorComponent {
  _selectedDate: Date = new Date();

  @Input()
  set selectedDate(date: Date | null) {
    this._selectedDate = date ?? new Date();
  }

  _capacityType: CapacityTypeEnum = CapacityTypeEnum.Weekly;
  @Input()
  set capacityType(value: CapacityTypeEnum) {
    this._capacityType = value;
    this.setWithSurrounding();
  }

  dateList: Date[] = [];

  @Output()
  readonly selectDate = new EventEmitter<Date>();

  readonly monthTranslations = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december'
  ].map((month) => 'shared.calendar.months.' + month);

  constructor(private readonly translateService: TranslocoService) {
    this.setWithSurrounding();
  }

  getDisplayedTimeUnit(date: Date): string {
    if (
      this._capacityType === CapacityTypeEnum.Daily ||
      this._capacityType === CapacityTypeEnum.Weekly
    ) {
      return `${this.translateService.translate(
        this.monthTranslations[date.getUTCMonth()]
      )} ${date.getFullYear()}`;
    } else if (this._capacityType === CapacityTypeEnum.Quarterly) {
      return this.getFYString(date);
    }

    return date.getFullYear().toString();
  }

  setWithSurrounding(event: Date | null = null) {
    if (event !== null) {
      this._selectedDate = event;
      this.selectDate.next(this._selectedDate);
    }

    this.dateList = [this._selectedDate];

    if (
      this._capacityType === CapacityTypeEnum.Daily ||
      this._capacityType === CapacityTypeEnum.Weekly
    ) {
      for (let i = 1; i < 13; i++) {
        const prev = subMonths(this._selectedDate, i);

        const next = addMonths(this._selectedDate, i);

        this.dateList.unshift(prev);
        this.dateList.push(next);
      }
    } else {
      for (let i = 1; i < 5; i++) {
        const prev = subYears(this._selectedDate, i);
        const next = addYears(this._selectedDate, i);

        this.dateList.unshift(prev);
        this.dateList.push(next);
      }
    }
  }

  selectNext() {
    const current = new Date(this._selectedDate);
    if (
      this._capacityType === CapacityTypeEnum.Daily ||
      this._capacityType === CapacityTypeEnum.Weekly
    ) {
      current.setMonth(this._selectedDate.getMonth() + 1);
    } else {
      current.setFullYear(this._selectedDate.getFullYear() + 1);
    }
    this._selectedDate = current;
    this.setWithSurrounding();
    this.selectDate.next(current);
  }

  selectPrevious() {
    const current = new Date(this._selectedDate);
    if (
      this._capacityType === CapacityTypeEnum.Daily ||
      this._capacityType === CapacityTypeEnum.Weekly
    ) {
      current.setMonth(this._selectedDate.getMonth() - 1);
    } else {
      current.setFullYear(this._selectedDate.getFullYear() - 1);
    }
    this._selectedDate = current;
    this.setWithSurrounding();
    this.selectDate.next(current);
  }

  getFYString(date: Date) {
    return `${this.translateService.translate('shared.calendar.abbreviations.fiscal-year')} ${(date.getFullYear() - 1).toString().slice(-2)}/${date.getFullYear().toString().slice(-2)}`
  }
}
