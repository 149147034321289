import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AppUserService, DateService, EmployeeSettingsService, MessageService } from '@ceres/shared/services';
import { CustomFilter, FilterOption } from '../../../myceres/components/settingsv2/custom-filter/custom-filter';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CustomFilterSaveDialog } from '../custom-filter-save-dialog/custom-filter-save-dialog.component';
import { IUser } from '@ceres/domain';
import { DateFilter, Filter, FilterService, MultiValueFilter } from '@ceres/filter';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { getNewFilterOptions, isColumnFilterApplied, resetColumnFilters } from './custom-filter-functions';
import { TranslocoService } from '@ngneat/transloco';
import { ListItem } from '@ceres/frontend-helper';

@Component({
  selector: 'ceres-custom-filter-select',
  templateUrl: './custom-filter.component.html',
  styleUrls: ['./custom-filter.component.scss']
})
export class CustomFilterComponent implements OnInit, OnDestroy {
  @Input() filterSection: string;
  @Input() filterService: FilterService;


  private destroy$: Subject<boolean> = new Subject<boolean>();
  private currentUser: IUser;
  customFilterOptions: CustomFilter[];
  selectedCustomFilter: CustomFilter;
  customFilterToSave: CustomFilter;
  isLoading = false;

  constructor(
    private readonly employeeSettingsService: EmployeeSettingsService,
    private appUserService: AppUserService,
    private dialog: MatDialog,
    private messageService: MessageService,
    private translateService: TranslocoService,
    private dateService: DateService
  ) {
  }

  ngOnInit() {
    this.appUserService.loggedInUser$.pipe(
      tap(user => this.currentUser = user),
      switchMap(() => this.employeeSettingsService.getCustomFilterBySection(this.currentUser.details.gid, this.filterSection)),
      tap(data => this.customFilterOptions = data),
      takeUntil(this.destroy$))
    .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public resetFilters(): void {
    this.selectedCustomFilter = null;
    resetColumnFilters(this.filterSection, this.filterService, this.dateService);
    this.filterService.setDisplayedColumns(this.filterService.initialColumnSetup);
    this.filterService.triggerFilter();
  }


  protected saveCustomFilter() {
    const filtersApplied = this.getAppliedFilters();
    this.filterService.displayedColumns.pipe(
      tap(displayedColumns => this.openSaveCustomFilterDialog(displayedColumns, filtersApplied)),
      take(1),
      takeUntil(this.destroy$))
    .subscribe();
  }

  protected applySectionFilters(filter: CustomFilter) {
    const filters = filter.filterOptions.find((item) => item.filterName === 'filter-object').filterValue;
    this.filterService.setDisplayedColumns(filter.filterDisplayedColumns);
    const transformedFilters = this.transformFilterObjectValues(filters);
    resetColumnFilters(this.filterSection, this.filterService, this.dateService);
    transformedFilters.forEach((filter) => {
      const indexToUpdate = this.filterService.columnFilters.findIndex((columnFilter) => columnFilter.key === filter.key);

      if(indexToUpdate !== -1) {
        // Replace the matching filter in columnFilters with the transformed filter
        this.filterService.columnFilters[indexToUpdate] = filter;
      }

      if(this.filterSection === 'projects' || this.filterSection === 'offers' || this.filterSection === 'services') {
        const indexToUpdate = this.filterService.globalFilters.findIndex(
          (globalFilter) => globalFilter.key === filter.key
        );

        if(indexToUpdate !== -1) {
          // Replace the matching filter in globalFilters with the transformed filter for the Dates
          this.filterService.globalFilters[indexToUpdate] = filter;
        }
      }
    });

    this.filterService.triggerFilter();
  }

  private transformFilterObjectValues(filterObject) {
    return filterObject.map((item) => {
      if(item.type !== 'date') {
        item = new MultiValueFilter(item.key, item.values);
        item.isApplied = true;
      } else {
        item = this.applyDateFilter(item);
      }
      return item;
    });
  }

  private saveFilter(filterName: string) {
    const newFilterOptions = getNewFilterOptions(this.filterSection, this.filterService);
    this.filterService.displayedColumns.pipe(
      tap(displayedColumns => this.setCustomFilterToSave(filterName, newFilterOptions, displayedColumns)),
      switchMap(() => this.employeeSettingsService.createCustomFilter(this.currentUser.details.gid, this.customFilterToSave)),
      switchMap(() => this.employeeSettingsService.getCustomFilterBySection(this.currentUser.details.gid, this.filterSection)),
      tap(customFilterData => this.setSavedCustomFilterOption(customFilterData, filterName)),
      take(1),
      takeUntil(this.destroy$))
    .subscribe();
  }

  private setCustomFilterToSave(filterName: string, newFilterOptions: FilterOption[], displayedColumns: ListItem[]) {
    this.customFilterToSave = {
      filterSection: this.filterSection,
      sortKey: undefined,
      gid: this.currentUser.details.gid,
      filterTitle: filterName,
      filterOptions: newFilterOptions,
      filterDisplayedColumns: displayedColumns.filter(column => column.selected)
    };
  }

  private setSavedCustomFilterOption(customFilterData: CustomFilter[], filterName: string) {
    this.customFilterOptions = customFilterData;
    this.selectedCustomFilter = this.customFilterOptions.find(item => item.filterTitle === filterName);
    this.showMessage('control-center.general.save-success', 1);
    this.isLoading = false;
  }

  private getAppliedFilters() {
    return this.filterService.appliedFilters$.getValue().filter(item => item.key !== 'startDate' && item.key !== 'endDate' && item.key !== 'startdate' && item.key !== 'expiryDate');
  }

  private applyDateFilter(item) {
    const dateFilter = new DateFilter(item.key);
    dateFilter.start = new Date(item.from);
    dateFilter.end = new Date(item.to);
    dateFilter.isApplied = true;
    return dateFilter;
  }

  private openSaveCustomFilterDialog(displayedColumns: ListItem[], filtersApplied: Filter[]) {
    const columnsFilterApplied = isColumnFilterApplied(displayedColumns, this.filterService.initialColumnSetup);
    if(filtersApplied.length !== 0 || !columnsFilterApplied) {
      this.dialog
      .open(CustomFilterSaveDialog)
      .afterClosed()
      .pipe(
        tap(filterName => {
          if(!filterName) return;
          this.isLoading = true;
          this.saveFilter(filterName);
        }),
        takeUntil(this.destroy$))
      .subscribe();
    } else {
      this.showMessage('my-ceres.settings.custom-filter.no-filters-applied', 2);
    }
  }

  private showMessage(msg: string, type: number) {
    this.messageService.pushMessage({
      message: msg,
      title: this.translateService.translate('my-ceres.settings.options.custom-filter'),
      type
    });
  }
}
