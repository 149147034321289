<div class="row dialog-header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div class="col-11 left">
    <h4>{{'menu.edit-impersonation' | transloco}}</h4>
  </div>
  <div class="col-1 right">
    <button (click)="dialogRef.close()">
      <i class="a-icon a-icon--close"></i>
    </button>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <mat-tab-group>
      <mat-tab label="{{'menu.other-impersonate' | transloco}}">
        <div class="table-container">
          <mat-table [dataSource]="dataSourceSecond" [style.width.px]="gridWidth" matSort>
            <ng-container matColumnDef="user">
              <mat-header-cell *matHeaderCellDef><span>{{'shared.miscellaneous.name' | transloco}}</span></mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{element.user.name}}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef>
                <ceres-user-picker (selected)="draftItem.user = $event" [extern]="false" [multiple]="false"
                  [placeholder]="'employee.buttons.select-employee' | transloco" [value]="draftItem.user">
                </ceres-user-picker>
              </mat-footer-cell>
            </ng-container>
            <ng-container matColumnDef="validFrom">
              <mat-header-cell *matHeaderCellDef><span>{{'time-management.capacity.valid-from' | transloco}}</span></mat-header-cell>
              <mat-cell *matCellDef="let element">
                <input [(ngModel)]="element.validFrom" [matDatepicker]="validFrom" matInput />
                <mat-datepicker-toggle [for]="validFrom" matPrefix></mat-datepicker-toggle>
                <mat-datepicker #validFrom></mat-datepicker>
              </mat-cell>
              <mat-footer-cell *matFooterCellDef>
                <input [(ngModel)]="draftItem.validFrom" [matDatepicker]="validFromDraft" matInput />
                <mat-datepicker-toggle [for]="validFromDraft" matPrefix></mat-datepicker-toggle>
                <mat-datepicker #validFromDraft></mat-datepicker>
              </mat-footer-cell>
            </ng-container>
            <ng-container matColumnDef="validTo">
              <mat-header-cell *matHeaderCellDef><span>{{'shared.properties.valid-to' | transloco}}</span></mat-header-cell>
              <mat-cell *matCellDef="let element">
                <input [(ngModel)]="element.validTo" [matDatepicker]="validTo" matInput />
                <mat-datepicker-toggle [for]="validTo" matPrefix></mat-datepicker-toggle>
                <mat-datepicker #validTo></mat-datepicker>
              </mat-cell>
              <mat-footer-cell *matFooterCellDef>
                <input [(ngModel)]="draftItem.validTo" [matDatepicker]="validToDraft" matInput />
                <mat-datepicker-toggle [for]="validToDraft" matPrefix></mat-datepicker-toggle>
                <mat-datepicker #validToDraft></mat-datepicker>
              </mat-footer-cell>
            </ng-container>
            <ng-container matColumnDef="save">
              <mat-header-cell *matHeaderCellDef>
                <mat-icon>save</mat-icon>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <button (click)="updateItem(element)" [disabled]="!element.validTo || !element.validFrom">
                  <mat-icon>save</mat-icon>
                </button>
              </mat-cell>
              <mat-footer-cell *matFooterCellDef>
                <button (click)="createItem()"
                  [disabled]="!draftItem.validTo || !draftItem.validFrom || !draftItem.user">
                  <mat-icon>add</mat-icon>
                </button>
              </mat-footer-cell>
            </ng-container>
            <ng-container matColumnDef="remove">
              <mat-header-cell *matHeaderCellDef>
                <i class="a-icon a-icon--delete"></i>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <button (click)="deleteItem(element)">
                  <i class="a-icon a-icon--delete"></i>
                </button>
              </mat-cell>
              <mat-footer-cell *matFooterCellDef>

              </mat-footer-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            <mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></mat-footer-row>
          </mat-table>
        </div>
      </mat-tab>
      <mat-tab label="{{'menu.impersonate' | transloco}}">
        <div class="table-container">
          <mat-table [dataSource]="dataSource" [style.width.px]="gridWidth" matSort>
            <ng-container matColumnDef="user">
              <mat-header-cell *matHeaderCellDef><span>{{'shared.miscellaneous.name' | transloco}}</span></mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{element.impersonated.name}}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="validFrom">
              <mat-header-cell *matHeaderCellDef><span>{{'time-management.capacity.valid-from' | transloco}}</span></mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.validFrom | localizedDate }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="validTo">
              <mat-header-cell *matHeaderCellDef><span>{{'shared.properties.valid-to' | transloco}}</span></mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.validTo | localizedDate }}
              </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumnsSecond"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumnsSecond;"></mat-row>
          </mat-table>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
