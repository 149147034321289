import {
  ContractComment as CeresContractComment,
  Contract
} from '@ceres/domain';

import { Employee } from "@ceres/shared/services";

export class ContractComment implements CeresContractComment {
  id: number;
  created: Date;
  creator: Employee;
  content: string;
  isReviewed: boolean;
  contract: Contract;

  constructor(object?: Partial<ContractComment>) {
    Object.assign(this, object);
  }
}
