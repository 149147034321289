export interface ProjectCommercialInfo {
  id: number;
  orderPosNumber: string;
  orderQuantity: number;
  pricePerUnit: number;
  price: number;
  accountedSAP: number;
  accounted: boolean;
  new: boolean;
}

