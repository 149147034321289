<ng-container [ngSwitch]="navigationModule">
  <ng-container *ngSwitchCase="'projects'">
    <div class="d-flex flex-wrap inline-navbar">
        <div class="navbar-nav-item" [routerLinkActiveOptions]="{ exact: true }" routerLink="/projects" [routerLinkActive]="'active'">{{ 'projects.general.title-projects' | transloco }}</div>
        <div *ngIf="'projects' == currentRoute">
          <ng-container [ngTemplateOutlet]="content"></ng-container>
        </div>

        <ng-container *ceresRequiredFeatures="[features.projects.groups]">
          <div class="navbar-nav-item" [routerLinkActiveOptions]="{ exact: true }" routerLink="/projectgroup" [routerLinkActive]="'active'">{{ 'projects.project-properties.project-group' | transloco }}</div>
          <div *ngIf="'projectgroup' == currentRoute">
            <ng-container [ngTemplateOutlet]="content"></ng-container>
          </div>
        </ng-container>


        <ng-container *ceresRequiredFeatures="[features.projects.charging]">
          <div class="navbar-nav-item" [routerLink]="!appUserService.isMerchant() ? '/accounting/overview' : '/accounting/merchant'" [routerLinkActive]="'active'">{{ (!appUserService.isMerchant() ? 'projects.general.title-accounting' : 'projects.general.title-merchant') | transloco }}</div>
          <div *ngIf="'accounting' == currentRoute">
            <ng-container [ngTemplateOutlet]="content"></ng-container>
          </div>
        </ng-container>

        <ng-container *ceresRequiredFeatures="[features.projects.nrsView]">
          <div class="navbar-nav-item" *ngIf="appUserService.hasPermission(permission.project.nrs)" [routerLinkActiveOptions]="{ exact: true }" routerLink="/projects/nrs" [routerLinkActive]="'active'">{{ 'projects.project-nrs-view.title' | transloco }}</div>
          <div *ngIf="'nrs' == currentRoute">
            <ng-container [ngTemplateOutlet]="content"></ng-container>
          </div>
        </ng-container>

        <ng-container *ceresRequiredFeatures="[features.projects.preStaffing]">
          <ng-container *ngIf="appUserService.hasPermission(permission.project.preStaffing.readAll)">
            <div class="navbar-nav-item" [routerLinkActiveOptions]="{ exact: true }" routerLink="/prestaffing" [routerLinkActive]="'active'">{{ 'projects.pre-staffing.title' | transloco }}</div>
            <div *ngIf="'prestaffing' == currentRoute">
              <ng-container [ngTemplateOutlet]="content"></ng-container>
            </div>
          </ng-container>
        </ng-container>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'business-partner'">
    <div class="d-flex flex-wrap inline-navbar">
      <ng-container *ngIf="appUserService.hasPermission(permission.businesspartner.readAll)">
        <div class="navbar-nav-item" [routerLinkActiveOptions]="{ exact: true }" routerLink="/businesspartner" [routerLinkActive]="'active'">{{ 'businesspartner.general.title' | transloco }}</div>
        <div *ngIf="'business-partner' == currentRoute">
          <ng-container [ngTemplateOutlet]="content"></ng-container>
        </div>
      </ng-container>

    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'employees'">
    <div class="d-flex flex-wrap inline-navbar">
      <ng-container *ngIf="appUserService.hasPermission(permission.employee.readAll)">
        <div class="navbar-nav-item" [routerLinkActiveOptions]="{ exact: true }" routerLink="/employees" [routerLinkActive]="'active'">{{ 'employee.general.employees-title' | transloco }}</div>
        <div *ngIf="'employees' == currentRoute">
          <ng-container [ngTemplateOutlet]="content"></ng-container>
        </div>
      </ng-container>

      <ng-container *ceresRequiredFeatures="[features.employees.skills]">
        <div class="navbar-nav-item" *ngIf="appUserService.hasPermission(permission.employee.skill)" routerLink="/employees/skills" [routerLinkActive]="'active'">{{ 'employee.general.skills-title' | transloco }}</div>
        <div *ngIf="'skills' == currentRoute">
          <ng-container [ngTemplateOutlet]="content"></ng-container>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'services'">
    <div class="d-flex flex-wrap inline-navbar">
      <ng-container *ceresRequiredFeatures="[features.services.enabled]">
        <div class="navbar-nav-item" *ngIf="appUserService.hasPermission(permission.services.readAll)" [routerLinkActiveOptions]="{ exact: true }" routerLink="/services" [routerLinkActive]="'active'">{{ 'services.general.title' | transloco }}</div>
        <div *ngIf="'services' == currentRoute">
          <ng-container [ngTemplateOutlet]="content"></ng-container>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'sales'">
    <div class="d-flex flex-wrap inline-navbar">
      <ng-container *ceresRequiredFeatures="[features.sales.businessOpportunities]">
        <div class="navbar-nav-item" *ngIf="appUserService.hasPermission(permission.bop.readAll)" [routerLinkActiveOptions]="{ exact: true }" routerLink="/bop" [routerLinkActive]="'active'">{{ 'bop.general.bop-title' | transloco }}</div>
        <div *ngIf="'bop' == currentRoute">
          <ng-container [ngTemplateOutlet]="content"></ng-container>
        </div>
      </ng-container>

      <ng-container *ceresRequiredFeatures="[features.sales.salesActivities]">
        <div class="navbar-nav-item" *ngIf="appUserService.hasPermission(permission.timesheet)" [routerLinkActiveOptions]="{ exact: true }" routerLink="/sales-activity" [routerLinkActive]="'active'">{{ 'sales-activity.general.title' | transloco }}</div>
        <div *ngIf="'sales-activity' == currentRoute">
          <ng-container [ngTemplateOutlet]="content"></ng-container>
        </div>
      </ng-container>

      <ng-container *ceresRequiredFeatures="[features.sales.salesFunnel]">
        <div class="navbar-nav-item" *ngIf="appUserService.hasPermission(permission.controlling.salesFunnel)" [routerLinkActiveOptions]="{ exact: true }" routerLink="/controlling/sales-funnel" [routerLinkActive]="'active'">{{ 'salesfunnel.general.title' | transloco }}</div>
        <div *ngIf="'sales-funnel' == currentRoute">
          <ng-container [ngTemplateOutlet]="content"></ng-container>
        </div>
      </ng-container>

      <ng-container *ceresRequiredFeatures="[features.sales.offers]">
        <div class="navbar-nav-item" *ngIf="appUserService.hasPermission(permission.contract.readAll)" [routerLinkActiveOptions]="{ exact: true }" routerLink="/contract" [routerLinkActive]="'active'">{{ 'offers.general.title' | transloco }}</div>
        <div *ngIf="'contract' == currentRoute">
          <ng-container [ngTemplateOutlet]="content"></ng-container>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'timesheets'">
    <div class="d-flex flex-wrap inline-navbar">
      <ng-container *ceresRequiredFeatures="[features.timeManagement.timeSheets]">
        <div class="navbar-nav-item" *ngIf="appUserService.hasPermission(permission.timesheet)" [routerLinkActiveOptions]="{ exact: true }" routerLink="/timesheet" [routerLinkActive]="'active'">{{ 'time-management.general.title' | transloco }}</div>
        <div *ngIf="'working-hours' == currentRoute">
          <ng-container [ngTemplateOutlet]="content"></ng-container>
        </div>
      </ng-container>

      <ng-container *ceresRequiredFeatures="[features.timeManagement.capacity]">
        <div class="navbar-nav-item" *ngIf="appUserService.hasPermission(permission.capacities)" [routerLinkActiveOptions]="{ exact: true }" routerLink="/capacities" [routerLinkActive]="'active'">{{ 'time-management.general.capacity-planning-title' | transloco }}</div>
        <div *ngIf="'capacity' == currentRoute">
          <ng-container [ngTemplateOutlet]="content"></ng-container>
        </div>
      </ng-container>

      <ng-container *ceresRequiredFeatures="[features.timeManagement.absences]">
        <div class="navbar-nav-item" *ngIf="appUserService.hasPermission(permission.absences)" [routerLinkActiveOptions]="{ exact: true }" routerLink="/absences" [routerLinkActive]="'active'">{{ 'time-management.general.absences-title' | transloco }}</div>
        <div *ngIf="'absences' == currentRoute">
          <ng-container [ngTemplateOutlet]="content"></ng-container>
        </div>
      </ng-container>

      <ng-container *ceresRequiredFeatures="[features.timeManagement.workload]">
        <div class="navbar-nav-item" *ngIf="appUserService.hasPermission(permission.workload.readAll)" [routerLinkActiveOptions]="{ exact: true }" routerLink="/workload" [routerLinkActive]="'active'">{{ 'time-management.general.workload-title' | transloco }}</div>
        <div *ngIf="'workload' == currentRoute">
          <ng-container [ngTemplateOutlet]="content"></ng-container>
        </div>
      </ng-container>

      <ng-container *ceresRequiredFeatures="[features.timeManagement.transactionalTimeTracking]">
        <div class="navbar-nav-item" *ngIf="appUserService.hasPermission(permission.timesheet)" [routerLinkActiveOptions]="{ exact: true }" routerLink="/transactional-hours" [routerLinkActive]="'active'">{{ 'time-management.general.transactional-hours-title' | transloco }}</div>
        <div *ngIf="'transactional-hours' == currentRoute">
          <ng-container [ngTemplateOutlet]="content"></ng-container>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'controlling'">
    <div class="d-flex flex-wrap inline-navbar">
      <ng-container *ceresRequiredFeatures="[features.reporting.dataQuality]">
        <div class="navbar-nav-item" *ngIf="appUserService.hasPermission(permission.controlling.quality)" [routerLinkActiveOptions]="{ exact: true }" routerLink="/controlling/quality-projects" [routerLinkActive]="'active'">{{ 'reporting.general.data-quality-title' | transloco }}</div>
        <div *ngIf="'quality-projects' == currentRoute">
          <ng-container [ngTemplateOutlet]="content"></ng-container>
        </div>
      </ng-container>

      <ng-container *ceresRequiredFeatures="[features.reporting.pulseDashboard]">
        <div class="navbar-nav-item" ><a href="https://pulse.siemens.cloud/project/gmsreporting/dash/2506" target="_blank" style="color: inherit; text-decoration: inherit;">{{ 'reporting.general.pulse-title' | transloco }}</a></div>
      </ng-container>

      <ng-container *ceresRequiredFeatures="[features.reporting.costReport]">
        <div class="navbar-nav-item" *ngIf="appUserService.hasPermission(permission.costReport)"  [routerLinkActiveOptions]="{ exact: true }" routerLink="/cost-report" [routerLinkActive]="'active'">{{ 'reporting.general.cost-report-title' | transloco }}</div>
        <div *ngIf="'cost-report' == currentRoute">
          <ng-container [ngTemplateOutlet]="content"></ng-container>
        </div>
      </ng-container>

      <ng-container *ceresRequiredFeatures="[features.reporting.workingHoursReport]">
        <div class="navbar-nav-item" *ngIf="appUserService.hasPermission(permission.workingHoursReport)" [routerLinkActiveOptions]="{ exact: true }" routerLink="/working-hours-report" [routerLinkActive]="'active'">{{ 'time-management.general.working-hours-report' | transloco }}</div>
        <div *ngIf="'working-hours-report' == currentRoute">
          <ng-container [ngTemplateOutlet]="content"></ng-container>
        </div>
      </ng-container>

      <ng-container *ceresRequiredFeatures="[features.reporting.gmsReportingDemo]">
        <div class="navbar-nav-item" *ngIf="appUserService.hasPermission(permission.reports)" [routerLinkActiveOptions]="{ exact: true }" routerLink="/gmsreporting" [routerLinkActive]="'active'">{{ 'reporting.general.gms-reporting-title' | transloco }}</div>
        <div *ngIf="'gms' == currentRoute">
          <ng-container [ngTemplateOutlet]="content"></ng-container>
        </div>
      </ng-container>

      <ng-container *ceresRequiredFeatures="[features.reporting.productivityReport]">
        <div class="navbar-nav-item" *ngIf="appUserService.hasPermission(permission.productivityReport)" [routerLinkActiveOptions]="{ exact: true }" routerLink="/reports/productivity-report" [routerLinkActive]="'active'">{{ 'reporting.general.productivity-title' | transloco }}</div>
        <div *ngIf="'productivity-report' == currentRoute">
          <ng-container [ngTemplateOutlet]="content"></ng-container>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'controlling-todo2'">
    <div class="d-flex flex-wrap inline-navbar">
      <ng-container *ceresRequiredFeatures="[features.reporting.dataQuality]">
        <div class="navbar-nav-item" *ngIf="appUserService.hasPermission(permission.controlling.quality)" [routerLinkActiveOptions]="{ exact: true }" routerLink="/controlling/quality-bops" [routerLinkActive]="'active'">{{ 'reporting.general.data-quality-title' | transloco }}</div>
        <div *ngIf="'quality-projects' == currentRoute">
          <ng-container [ngTemplateOutlet]="content"></ng-container>
        </div>
      </ng-container>

      <ng-container *ceresRequiredFeatures="[features.reporting.pulseDashboard]">
        <div class="navbar-nav-item" ><a href="https://pulse.siemens.cloud/project/gmsreporting/dash/2506" target="_blank" style="color: inherit; text-decoration: inherit;">{{ 'reporting.general.pulse-title' | transloco }}</a></div>
      </ng-container>

      <ng-container *ceresRequiredFeatures="[features.reporting.costReport]">
        <div class="navbar-nav-item" *ngIf="appUserService.hasPermission(permission.costReport)"  [routerLinkActiveOptions]="{ exact: true }" routerLink="/cost-report" [routerLinkActive]="'active'">{{ 'reporting.general.cost-report-title' | transloco }}</div>
        <div *ngIf="'cost-report' == currentRoute">
          <ng-container [ngTemplateOutlet]="content"></ng-container>
        </div>
      </ng-container>

      <ng-container *ceresRequiredFeatures="[features.reporting.workingHoursReport]">
        <div class="navbar-nav-item" *ngIf="appUserService.hasPermission(permission.workingHoursReport)" [routerLinkActiveOptions]="{ exact: true }" routerLink="/working-hours-report" [routerLinkActive]="'active'">{{ 'time-management.general.working-hours-report' | transloco }}</div>
        <div *ngIf="'working-hours-report' == currentRoute">
          <ng-container [ngTemplateOutlet]="content"></ng-container>
        </div>
      </ng-container>

      <ng-container *ceresRequiredFeatures="[features.reporting.gmsReportingDemo]">
        <div class="navbar-nav-item" *ngIf="appUserService.hasPermission(permission.reports)" [routerLinkActiveOptions]="{ exact: true }" routerLink="/gmsreporting" [routerLinkActive]="'active'">{{ 'reporting.general.gms-reporting-title' | transloco }}</div>
        <div *ngIf="'gms' == currentRoute">
          <ng-container [ngTemplateOutlet]="content"></ng-container>
        </div>
      </ng-container>

      <ng-container *ceresRequiredFeatures="[features.reporting.productivityReport]">
        <div class="navbar-nav-item" *ngIf="appUserService.hasPermission(permission.productivityReport)" [routerLinkActiveOptions]="{ exact: true }" routerLink="/reports/productivity-report" [routerLinkActive]="'active'">{{ 'reporting.general.productivity-title' | transloco }}</div>
        <div *ngIf="'productivity-report' == currentRoute">
          <ng-container [ngTemplateOutlet]="content"></ng-container>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <div>NO CONTENT FOUND</div>
  </ng-container>
</ng-container>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>

