import { Component, OnInit, Inject, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Employee, BusinessArea } from '@ceres/domain';
import { ItemReference, BopStatus, TranslationOptionProbabilityClass, TranslationOption } from '@ceres/domain';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { DateService } from "@ceres/shared/services";
import { BopService } from "../../services/bop.service";
import { Router } from '@angular/router';
import { AppUserService } from "@ceres/shared/services";
import { MessageService } from "@ceres/shared/services";
import { Portfolio } from "../../models";
import { BusinessPartner } from '@ceres/domain';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Bop } from "../../models";
import { format } from 'date-fns';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ceres-bop-new-dialog',
  templateUrl: './bop-new-dialog.component.html',
  styleUrls: ['./bop-new-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BopNewDialogComponent implements OnInit, OnDestroy {
  public portfolios: Portfolio[];
  public bopStatus: BopStatus[];
  public probabilityClasses: TranslationOptionProbabilityClass[];
  public declineReasons: TranslationOption[];

  public burgerOpened = false;
  private checked = false;
  public businessAreasFilterCtrl: FormControl<string> = new FormControl<string>('');
  public businessAreasOptions: ReplaySubject<BusinessArea[]> = new ReplaySubject<BusinessArea[]>(1);
  public businessAreas: BusinessArea[];
  public plannedProjectDuration: number;
  private bopNumber: string;
  public formGroup: FormGroup;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private bopService: BopService,
    private router: Router,
    private appUserService: AppUserService,
    private messageService: MessageService,
    private dateService: DateService,
    private dialogRef: MatDialogRef<BopNewDialogComponent>
  ) {
  }

  public get bop(): Bop {
    return this.formGroup ? this.formGroup.getRawValue() : new Bop();
  }

  public get totalVolume(): number {
    return this.bop.chargeVolumeOwn + this.bop.chargeVolumeOthers
      + this.bop.materialCosts;
  }

  public get totalProbability(): number {
    const probability = this.bop?.probabilityClass?.probability;
    return probability ? probability : 0;
  }

  private setDates() {
    const currentdate = new Date();
    let date = new Date();
    if (currentdate.getMonth() > 9) {
      date = new Date(
        currentdate.getFullYear() + 1,
        8,
        30
      );
    } else {
      date = new Date(
        currentdate.getFullYear(),
        8,
        30
      );
    }
    this.formGroup.patchValue({
      plannedProjectStart: new Date(),
      plannedProjectEnd: date,
    });
    this.calculateDuration();

    this.formGroup.controls.plannedProjectStart.valueChanges.subscribe(e => this.calculateDuration(e));
    this.formGroup.controls.plannedProjectEnd.valueChanges.subscribe(e => this.calculateDuration(null, e));
    this.formGroup.controls.bopStatus.valueChanges.subscribe(e => this.changeStatus(e));
  }

  private changeStatus(value: number) {
    this.formGroup.controls.declineReason.setValidators(value === 3 ? [Validators.required] : null);

    if (value === 3) {
      this.formGroup.controls.probabilityClass.disable();
      this.formGroup.patchValue({
        probabilityClass: 0
      });
    } else {
      this.formGroup.controls.probabilityClass.enable();
    }
  }

  pickerCondition(field: string) {
    return !this.formGroup.controls[field].valid && this.checked;
  }

  ngOnInit() {
    Promise.all([
      this.bopService.getPossibleBOPNumber(),
      this.bopService.getBOPMetadata()
    ]).then(([bopNumber, metaData]) => {
      this.businessAreas = metaData.lookupValues.businessAreas.sort((a, b) =>
        a.title.localeCompare(b.title)
      );
      this.businessAreas = this.businessAreas.filter(area => area.type === 'BusinessArea');
      this.businessAreasOptions.next(this.businessAreas.slice());
      this.bopStatus = metaData.lookupValues.bopStatusOptions;
      this.probabilityClasses = metaData.lookupValues.probabilityClasses;
      this.declineReasons = metaData.lookupValues.declinedReasons;
      this.bopNumber = bopNumber;
      this.buildForm();
    });

    this.businessAreasFilterCtrl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.filterBusinessAreas();
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  filterBusinessAreas() {
    if (!this.businessAreas) {
      return;
    }
    // get the search keyword
    let search = this.businessAreasFilterCtrl.value;
    if (!search) {
      this.businessAreasOptions.next(this.businessAreas.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filters
    this.businessAreasOptions.next(
      this.businessAreas.filter(value => value.name.toLowerCase().includes(search))
    );
  }

  public get expectedValue(): number {
    return this.totalVolume * (this.totalProbability / 100);
  }

  private buildForm() {
    this.formGroup = this.formBuilder.group({
      bopNumber: [{ value: this.bopNumber, disabled: true }],
      bopLeader: [this.appUserService.loggedInUser$.getValue().details, Validators.required],
      businessArea: [
        this.appUserService.loggedInUser$.getValue().details.businessArea
          ? this.appUserService.loggedInUser$.getValue().details.businessArea
          : this.bop.businessArea,
        Validators.required
      ],
      plannedProjectStart: [this.bop.plannedProjectStart, Validators.required],
      plannedProjectEnd: [this.bop.plannedProjectEnd, Validators.required],
      bopTitle: [this.bop.bopTitle, Validators.required],
      content: [this.bop.content],
      offerDate: [this.bop.offerDate],
      offerTitle: [this.bop.offerTitle],
      bopStatus: [this.bop.bopStatus, Validators.required],
      probabilityClass: [this.bop.probabilityClass, Validators.required],
      declineReason: [this.bop.declineReason],
      declineComment: [this.bop.declineComment],
      businessPartner: [this.bop.businessPartner, Validators.required],
      chargeVolumeOwn: [this.bop.chargeVolumeOwn],
      chargeVolumeOthers: [this.bop.chargeVolumeOthers],
      materialCosts: [this.bop.materialCosts],
    });
    this.setDates();
    this.changeBusinessPartner(this.data.data);
  }

  private calculateDuration(start?: Date, end?: Date) {
    this.plannedProjectDuration =
      this.dateService.dayDiff(
        start ? start : this.bop.plannedProjectStart,
        end ? end : this.bop.plannedProjectEnd
      );
  }

  compareLookups(item1: ItemReference, item2: ItemReference): boolean {
    return item1 && item2 && item1.id === item2.id;
  }

  changeBopLeader(employee: Employee) {
    this.formGroup.patchValue({
      bopLeader: employee ? employee : null
    });
  }

  changeBusinessPartner(partner: BusinessPartner) {
    this.formGroup.patchValue({
      businessPartner: partner ? partner : null
    });
  }

  async save() {
    this.checked = true;

    if (this.formGroup.valid) {
      const bopNumber = await this.bopService.getPossibleBOPNumber();

      if (bopNumber) {
        this.formGroup.patchValue({ bopNumber: bopNumber });

        let bop: Bop = {
          ...this.bop,
          ...this.formGroup.getRawValue(),
          plannedProjectStart: format(
            new Date(this.formGroup.get('plannedProjectStart').value),
            'yyyy-MM-dd'
          ),
          plannedProjectEnd: format(
            new Date(this.formGroup.get('plannedProjectEnd').value),
            'yyyy-MM-dd'
          )
        };

        this.bopService.createBop(bop).then(e => {
          if (e) {

            this.showMessage('control-center.general.save-success', 1);
            this.dialogRef.close(e);
          }
        });
      } else {
        this.showMessage('control-center.general.double-entry', 0);
      }
    } else {
      this.showMessage('control-center.general.required-fields-empty', 0);
    }
  }

  showMessage(msg, type) {
    this.messageService.pushMessage({
      message: msg,
      title: 'BOP',
      type
    });
  }

  public close() {
    this.dialogRef.close();
  }

}
