import { CreatedUpdated } from './created_updated';
import { DateRangeType } from '../enums/date-range-type.enum';

export interface AppSettings extends CreatedUpdated {
  id: number;

  dateRangeType: DateRangeType;
}

export interface AppSettingsChangeDateRangeTypeDto {
  type: DateRangeType;
}
