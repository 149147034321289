import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Impersonation, IUser } from '@ceres/domain';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ImpersonationService {
  constructor(private httpClient: HttpClient) {}

  async getByUser(user: IUser): Promise<Impersonation[]> {
    return await this.httpClient
      .get<Impersonation[]>(
        `${environment.edgeService}/impersonations/users/${user.id}`
      )
      .toPromise();
  }

  async getAll(user: IUser): Promise<Impersonation[]> {
    return await this.httpClient
      .get<Impersonation[]>(
        `${environment.edgeService}/impersonations/${user.id}`
      )
      .toPromise();
  }

  async create(impersonation: Impersonation) {
    return await this.httpClient
      .post<Impersonation[]>(
        `${environment.edgeService}/impersonations`,
        impersonation
      )
      .toPromise();
  }

  async update(impersonation: Impersonation) {
    return await this.httpClient
      .patch<Impersonation[]>(
        `${environment.edgeService}/impersonations/${impersonation.id}`,
        impersonation
      )
      .toPromise();
  }

  async delete(impersonation: Impersonation) {
    return await this.httpClient
      .delete<Impersonation[]>(
        `${environment.edgeService}/impersonations/${impersonation.id}`
      )
      .toPromise();
  }
}
