import { EmployeeSkill as CeresEmployeeSkill, Skill } from '@ceres/domain';

export class EmployeeSkill implements CeresEmployeeSkill {
  skill!: Skill;
  points!: number;

  constructor(object?: Partial<EmployeeSkill>) {
    Object.assign(this, object);
  }
}
