import { Module as CeresModule } from '@ceres/domain'

import { Activity } from './activity';

export class Module implements CeresModule {

  id: number;
  personalId: number;
  title: string;
  originalTitle: string;
  subtitle: string;
  originalSubtitle: string;
  activities: { activity: Activity; hours: number; isNew: boolean; }[];
  originalActivities: { activity: Activity; hours: number; }[];
  count: number;
  originalCount: number;
  price: number;
  originalPrice: number;
  comment: string;
  originalComment: string;


  constructor(object?: Partial<Module>) {
    Object.assign(this, object);
  }

}
