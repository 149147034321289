<div class="row dialog-header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div class="col-11 left">
    <h4>{{'bop.buttons.add-bop' | transloco}}</h4>
  </div>
  <div class="col-1 right">
    <button (click)="dialogRef.close()">
      <i class="a-icon a-icon--close"></i>
    </button>
  </div>
</div>
<div class="row mb-2">
  <div class="col-12">
    <div class="search-container">
      <ceres-search-field [dataSource]="dataSource"></ceres-search-field>
    </div>
  </div>
</div>

<div [hidden]="!filter">
  <div class="row">
    <div class="col-12">
      <div class="table-container">
        <mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="selected">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let element">
              <mat-checkbox [(ngModel)]="element.selected" (change)="changeSelected(element, $event)"></mat-checkbox>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="bopNumber">
            <mat-header-cell filter *matHeaderCellDef [sort]="true" [filter]="filterService.getColumnFilters('bopNumber')"
              name="{{ 'bop.properties.number' | transloco }}" [filterService]="filterService" column="bopNumber">
            </mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.bopNumber}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="bopTitle">
            <mat-header-cell filter *matHeaderCellDef [sort]="true" [filter]="filterService.getColumnFilters('bopTitle')"
              name="{{ 'bop.properties.title' | transloco }}" [filterService]="filterService" column="bopTitle">
            </mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.bopTitle}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="businessPartner">
            <mat-header-cell filter *matHeaderCellDef [sort]="true" [filter]="filterService.getColumnFilters('businessPartner.fullName')"
              name="{{ 'businesspartner.general.title' | transloco }}" [filterService]="filterService"
              column="businessPartner.fullName"></mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.businessPartner.fullName}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="bopLeader">
            <mat-header-cell filter *matHeaderCellDef [sort]="true" [filter]="filterService.getColumnFilters('bopLeader.name')"
              name="{{ 'bop.properties.responsible' | transloco }}" [filterService]="filterService"
              column="bopLeader.name"></mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.bopLeader.name}}</mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
      </div>
      <mat-paginator #paginator [pageSizeOptions]="[15, 30, 50]" [pageSize]="15"></mat-paginator>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="d-flex gap-10">
        <ceres-button (click)="closeDialog()" icon="check-mark"
                      type="button">{{'shared.buttons.apply' | transloco}}</ceres-button>
        <ceres-button (click)="dialogRef.close()" icon="close" [style]="'primary'" type="button">{{'shared.buttons.cancel' | transloco}}</ceres-button>
      </div>
    </div>
  </div>
</div>
<ceres-loading *ngIf="!filter"></ceres-loading>
