<ng-container *ngFor="let bar of cellData">
  <div
    class="bar bar--filled"
    *ngIf="bar.data !== null"
    [ngStyle]="{ 'background-color': '#' + bar.bgColor, color: bar.textColor }"
    [ngClass]="{
      'bar--before': bar.data.before,
      'bar--after': bar.data.after
    }"
    (click)="handleClick($event, bar)"
  >
    {{ bar.data.percent.toFixed(2) }} %
    <span class="bar--tooltip">
      {{ bar.projectTitle }}
    </span>
  </div>
  <div class="bar" *ngIf="bar.data === null"></div>
</ng-container>
