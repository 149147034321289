<div class="container d-flex align-items-center px-1">
    <ng-container *ngFor="let step of steps; let i = index">
        <div class="step" [ngClass]="{'active' : (i+1)<activeStep }" [matTooltip]="step" matTooltipClass="offerTemplateTooltip">
            <span *ngIf="(i+1)>=activeStep">{{i+1}}</span>
            <i *ngIf="(i+1)<activeStep" class="icon a-icon a-icon--check-mark"></i>
        </div>
        <div class="step-connector" *ngIf="steps.length!=i+1">

        </div>
    </ng-container>

</div>
