import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { BarCellData } from './bar-cell.model';

@Component({
  selector: 'ceres-bar-cell',
  templateUrl: './bar-cell.component.html',
  styleUrls: ['./bar-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BarCellComponent {
  @Input()
  cellData!: BarCellData[];

  @Output()
  readonly barClick = new EventEmitter<BarCellData>();

  handleClick(event: MouseEvent, bar: BarCellData) {
    event.stopPropagation();
    this.barClick.next(bar);
  }
}
