import { Employee } from './employee';
import { ProjectProfile } from './project-profile';
import { Activity, ApiActivity } from './activity';
import { CapacityTypeEnum } from '../enums/capacity-type.enum';
import { WorkloadTypeEnum } from '../enums/workload-type.enum';
import { FilterRecord } from '@ceres/filter';

export interface Workload {
  id: number;
  employee: Employee;
  workingHours: number;
  originalWorkingHours: number;
  originalComment: string;
  comment: string;
  accountingMonth: Date;
  year: number;
  month: number;
  mpNumber: number;
  project: ProjectProfile;
  activity: Activity;
}

export interface WorkloadByProject {
  activityId: 2;
  employeeId: 80;
  employeeName: string;
  validFrom: Date;
  activityName: string;
  activityTranslationKey: string;
  days: number;
  costsPerDay: number;
  pricePerDay: number;
  timeUnit: string;
}

export interface ApiWorkload {
  Id: number;
  Employee: Employee;
  EmployeeId: number;
  ProjectId: number;
  WorkingHours: number;
  Comment: string;
  AccountingMonth: string;
  Year: number;
  Month: number;
  MPNumber: number;
  Activity: ApiActivity;
  ActivityId: number;
}

export interface WorkloadChanges {
  new: Workload[];
  updated: Workload[];
  deleted: Workload[];
}

export interface WorkloadCapacity {
  from: string;
  to: string;
  hours: number;
}

export interface WorkloadGetRequestV2 {
  from: string;
  to: string;
  workloadType: WorkloadTypeEnum;
  capacityType: CapacityTypeEnum;
  businessAreas: string[];
  onlyProductive?: boolean;
  withStudents?: boolean;
  search?: string;
  filters?: FilterRecord[];
  size: number;
  page: number;
  sort?: string | string[];
}

export interface WorkloadFilterGetRequestV2 {
  workloadType: WorkloadTypeEnum;
  withStudents?: boolean;
  from: string;
  to: string;
  onlyProductive?: boolean;
}

export interface WorkloadBusinessArea {
  id: number;
  name: string;
}

export interface WorkloadEmployee {
  id: number;
  name: string;
  gid: string;
  targetHours: number | Record<string, number>;
  totalHours?: Record<string, number>;
  countryCode?: string;
  dailyTargetHours: number;
}

export interface WorkloadProject {
  id: number;
  name: string;
  mpNumber: number;
  projectStart: string | null;
  projectEnd: string | null;
}

export interface WorkloadEntityWithCapacity {
  capacities: WorkloadCapacity[];
  sum: number;
}

export interface WorkloadBusinessArea {
  id: number;
  name: string;
}

export interface WorkloadGetByEmployeeResponseV2WithActivities {
  entries: WorkloadGetByEmployeeResponseV2[];
  activities: Record<string, WorkloadActivity[]>;
}

export interface WorkloadGetByEmployeeResponseV2 {
  employee: WorkloadEmployee;
  sum: number;
  projects: {
    [projectId: string]: WorkloadProject & WorkloadEntityWithCapacity;
  };
}

export interface WorkloadActivity {
  id: number;
  name: string;
  shortName?: string;
  colorCode?: string;
}

export interface WorkloadGetByProjectResponseV2WithActivities {
  entries: WorkloadGetByProjectResponseV2[];
  activities: Record<string, WorkloadActivity[]>;
}

export interface WorkloadGetByProjectResponseV2 {
  project: WorkloadProject;
  sum: number;
  employees: {
    [employeeId: string]: WorkloadEmployee & WorkloadEntityWithCapacity;
  };
}

export type WorkloadGetByBusinessAreaResponseV2 = WorkloadEntityWithCapacity & {
  businessArea: WorkloadBusinessArea;
  targetHours: number | Record<string, number>;
};

export type WorkloadGetByEmployeeView = WorkloadGetByEmployeeResponseV2 & {
  project?: WorkloadProject;
  extended?: boolean;
};

export type WorkloadGetByProjectView = WorkloadGetByProjectResponseV2 & {
  employee?: WorkloadEmployee;
  extended?: boolean;
};

export type WorkloadView =
  | WorkloadGetByProjectView
  | WorkloadGetByEmployeeView
  | WorkloadGetByBusinessAreaResponseV2;

export interface WorkloadGetFiltersResponse {
  employees?: WorkloadEmployee[];
  businessAreas?: WorkloadBusinessArea[];
  projects?: WorkloadProject[];
}

export interface WorkloadPutRequestV2 {
  capacityType: CapacityTypeEnum;
  entries: {
    employeeId: number;
    projectId: number;
    activityId?: number;
    capacities: WorkloadCapacity[];
  }[];
}

export interface WorkloadDeleteRequestV2 {
  entries: {
    employeeId: number;
    projectId: number;
    from: string;
    to: string;
  }[];
}
