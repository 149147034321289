import forOwn from 'lodash-es/forOwn';
import isObject from 'lodash-es/isObject';
import mapValues from 'lodash-es/mapValues';

/**
 * Settings for the object converter.
 */
export interface ObjectConverterConfig {
  /**
   * If set, only not null properties are copied.
   */
  skipNullValues: boolean;
}

/**
 * Converter function applied while copying property.
 * @param srcVal Property value from source object.
 * @param defaultValue A default value to be used of value is empty.
 * @returns Value to be stored in the target object.
 */
export interface ValueConverter<TSource, TTarget> {
  (srcVal: TSource, defaultValue: TTarget, currentValue: TTarget): TTarget;
}

/**
 *  Converts an object to another using defined mappings and conversions.
 */
export interface ObjectConverter<TSource, TTarget> {
  (
    source: TSource,
    target?: TTarget,
    configOverride?: ObjectConverterConfig
  ): TTarget;
}

/**
 *  Converts an object to another using defined mappings and conversions.
 */
export interface MultiObjectConverter<TSource, TTarget> {
  (
    source: TSource[],
    targetFactory?: (source: TSource) => TTarget,
    configOverride?: ObjectConverterConfig
  ): TTarget[];
}

export type FieldMappings<TSource, TTarget> = Record<
  keyof TSource,
  (keyof TTarget)[]
>;

/**
 * Map to define conversion from one object to another.
 */
export interface ConversionMap<TSource, TTarget> {
  /**
   * Starts a new mapping.
   * Specifies property in source object from which values are copied to during conversion.
   * @param propName Name of source property.
   */
  from<TPropName extends keyof TSource>(
    propName: TPropName
  ): FromPropertyConversionMap<TSource, TPropName, TTarget>;

  /**
   * Build the actual converter function for all defined mappings.
   * @returns Converter function.
   */
  build(): ObjectConverter<TSource, TTarget>;

  /**
   * Build the actual converter function with all defined mappings.
   * This converter can be used to convert arrays directly.
   * @returns Converter function for arrays.
   */
  buildMulti(): MultiObjectConverter<TSource, TTarget>;

  /**
   * Build the actual converter function for all defined reverse mappings.
   * @returns Converter function.
   */
  buildReverse(): ObjectConverter<TTarget, TSource>;

  /**
   * Build the actual converter function for all defined reverse mappings.
   * This converter can be used to convert arrays directly.
   * @returns Converter function.
   */
  buildMultiReverse(): MultiObjectConverter<TTarget, TSource>;

  /**
   * Extends an existing map.
   * @param parentMap The map to inherit from
   */
  extends<TS extends Partial<TSource>, TT extends Partial<TTarget>>(
    parentMap: ConversionMap<TS, TT>
  ): ConversionMap<TSource, TTarget>;

  /**
   * Get all registered field mappings (source property -> target properties). Map only contains field names without converters.
   */
  fieldMappings: FieldMappings<TSource, TTarget>;

  /**
   * Get all registered field reverse mappings (target property -> source properties). Map only contains field names without converters.
   */
  fieldReverseMappings: FieldMappings<TTarget, TSource>;
}

type SamePropType<
  TSource,
  TSourcePropName extends keyof TSource,
  TTarget,
  TTargetPropName extends keyof TTarget
> = TSource[TSourcePropName] extends TTarget[TTargetPropName]
  ? TTargetPropName
  : never;

export interface FromPropertyConversionMap<
  TSource,
  TSourcePropName extends keyof TSource,
  TTarget
> {
  /**
   * Specifies property in target object to which values are copied to during conversion.
   * @param propName Name of target property.
   */
  to<TPropName extends keyof TTarget>(
    propName: SamePropType<TSource, TSourcePropName, TTarget, TPropName>
  ): ToPropertyConversionMap<
    TSource,
    TSourcePropName,
    TTarget,
    SamePropType<TSource, TSourcePropName, TTarget, TPropName>
  >;

  /**
   * Specifies property in target object to which values are copied to during conversion.
   * @param propName Name of target property.
   * @param converter No converter used.
   * @param defaultValue Default value used if source property is empty.
   */
  to<TPropName extends keyof TTarget>(
    propName: SamePropType<TSource, TSourcePropName, TTarget, TPropName>,
    converter: null | undefined,
    defaultValue: TTarget[TPropName]
  ): ToPropertyConversionMap<TSource, TSourcePropName, TTarget, TPropName>;

  /**
   * Specifies property in target object to which values are copied to during conversion.
   * @param propName Name of target property.
   * @param converter Converter applied before copying the value.
   * @param defaultValue Optional default value used if source property is empty.
   */
  to<TPropName extends keyof TTarget>(
    propName: TPropName,
    converter: ValueConverter<TSource[TSourcePropName], TTarget[TPropName]>,
    defaultValue?: TTarget[TPropName]
  ): RequiredToPropertyConversionMap<
    TSource,
    TSourcePropName,
    TTarget,
    TPropName
  >;

  /**
   * Specifies property in target object to which values are copied to during conversion.
   * This method does not ensure equal types on source and target side and should only be used in rare cases.
   * @param propName Name of target property.
   * @param converter Converter applied before copying the value.
   * @param defaultValue Optional default value used if source property is empty.
   */
  unsafeTo<TPropName extends keyof TTarget>(
    propName?: TPropName,
    converter?: ValueConverter<TSource[TSourcePropName], TTarget[TPropName]>,
    defaultValue?: TTarget[TPropName]
  ): ToPropertyConversionMap<TSource, TSourcePropName, TTarget, TPropName>;
}

export interface RequiredToPropertyConversionMap<
  TSource,
  TSourcePropName extends keyof TSource,
  TTarget,
  TTargetPropName extends keyof TTarget
> extends ConversionMap<TSource, TTarget> {
  /**
   * Defines the reverse mapping (dest -> source).
   * @param converter Converter applied before copying the value.
   * @param defaultValue Optional default value used if source property is empty.
   */
  reverse(
    converter?: ValueConverter<
      TTarget[TTargetPropName],
      TSource[TSourcePropName]
    >,
    defaultValue?: TSource[TSourcePropName]
  ): ConversionMap<TSource, TTarget>;
}

export interface ToPropertyConversionMap<
  TSource,
  TSourcePropName extends keyof TSource,
  TTarget,
  TTargetPropName extends keyof TTarget
> extends ConversionMap<TSource, TTarget> {
  /**
   * Defines the reverse mapping (dest -> source).
   * @param converter Optional converter applied before copying the value.
   * @param defaultValue Optional default value used if source property is empty.
   */
  reverse(
    converter?: ValueConverter<
      TTarget[TTargetPropName],
      TSource[TSourcePropName]
    >,
    defaultValue?: TSource[TSourcePropName]
  ): ConversionMap<TSource, TTarget>;
}

interface Conversion<
  TSource,
  TSourcePropName extends keyof TSource,
  TTarget,
  TTargetPropName extends keyof TTarget
> {
  source: TSourcePropName;
  target: TTargetPropName;
  converter: ValueConverter<TSource[TSourcePropName], TTarget[TTargetPropName]>;
  defaultValue?: TTarget[TTargetPropName];
}

type InternalConversionMap<TSource, TTarget> = Partial<
  Record<
    string | keyof TSource,
    Conversion<TSource, keyof TSource, TTarget, keyof TTarget>[]
  >
>;

const defaultConfig: ObjectConverterConfig = {
  skipNullValues: true
};

class ToPropertyConversionMapImpl<
  TSource,
  TSourcePropName extends keyof TSource,
  TTarget,
  TTargetPropName extends keyof TTarget
>
  implements
    RequiredToPropertyConversionMap<
      TSource,
      TSourcePropName,
      TTarget,
      TTargetPropName
    >,
    ConversionMap<TSource, TTarget>,
    ToPropertyConversionMap<TSource, TSourcePropName, TTarget, TTargetPropName>,
    ConversionMap<TSource, TTarget> {
  constructor(
    private readonly map: ConversionMapImpl<TSource, TTarget>,
    private readonly sourcePropName: TSourcePropName,
    private readonly targetPropName: TTargetPropName
  ) {}

  reverse(
    converter?: ValueConverter<
      TTarget[TTargetPropName],
      TSource[TSourcePropName]
    >,
    defaultValue?: TSource[TSourcePropName]
  ) {
    this.map.addReverse({
      source: this.targetPropName,
      target: this.sourcePropName,
      converter: converter,
      defaultValue: defaultValue
    });
    return this.map;
  }

  from<TPropName extends keyof TSource>(propName: TPropName) {
    return this.map.from(propName);
  }

  build(): ObjectConverter<TSource, TTarget> {
    return this.map.build();
  }

  buildMulti() {
    return this.map.buildMulti();
  }

  buildReverse(): ObjectConverter<TTarget, TSource> {
    return this.map.buildReverse();
  }

  buildMultiReverse() {
    return this.map.buildMultiReverse();
  }

  extends<TS extends Partial<TSource>, TT extends Partial<TTarget>>(
    parentMap: ConversionMap<TS, TT>
  ) {
    return this.map.extends(parentMap);
  }

  get fieldMappings() {
    return this.map.fieldMappings;
  }

  get fieldReverseMappings() {
    return this.map.fieldReverseMappings;
  }
}

class FromPropertyConversionMapImpl<
  TSource,
  TSourcePropName extends keyof TSource,
  TTarget
> implements FromPropertyConversionMap<TSource, TSourcePropName, TTarget> {
  constructor(
    private readonly map: ConversionMapImpl<TSource, TTarget>,
    private readonly sourcePropName: TSourcePropName
  ) {}

  to<TTargetPropName extends keyof TTarget>(
    propName: TTargetPropName,
    converter?: ValueConverter<
      TSource[TSourcePropName],
      TTarget[TTargetPropName]
    >,
    defaultValue?: TTarget[TTargetPropName]
  ): ToPropertyConversionMap<
    TSource,
    TSourcePropName,
    TTarget,
    TTargetPropName
  > {
    return this.unsafeTo(propName, converter, defaultValue);
  }

  unsafeTo<TPropName extends keyof TTarget>(
    propName?: TPropName,
    converter?: ValueConverter<TSource[TSourcePropName], TTarget[TPropName]>,
    defaultValue?: TTarget[TPropName]
  ): ToPropertyConversionMap<TSource, TSourcePropName, TTarget, TPropName> {
    this.map.add({
      source: this.sourcePropName,
      target: propName,
      converter: converter,
      defaultValue: defaultValue
    });
    return new ToPropertyConversionMapImpl(
      this.map,
      this.sourcePropName,
      propName
    );
  }
}

function convertProperty<
  TSource,
  TSourcePropName extends keyof TSource,
  TTarget,
  TTargetPropName extends keyof TTarget
>(
  value: TSource[TSourcePropName],
  {
    target: targetProp,
    converter,
    defaultValue
  }: Conversion<TSource, TSourcePropName, TTarget, TTargetPropName>,
  target: TTarget
) {
  let targetValue = target[targetProp];
  if (converter) {
    targetValue = converter(value, defaultValue, targetValue);
  } else if (value === null) {
    targetValue = null;
  } else if (value != null) {
    targetValue = (value as unknown) as TTarget[TTargetPropName];
  } else if (defaultValue) {
    targetValue = defaultValue;
  }
  target[targetProp] = targetValue;
}

function createObjectConverter<TSource, TTarget>(
  map: InternalConversionMap<TSource, TTarget>,
  globalConfig: ObjectConverterConfig
): ObjectConverter<TSource, TTarget> {
  return (
    source: TSource,
    target?: TTarget,
    config?: ObjectConverterConfig
  ): TTarget => {
    if (!isObject(source)) {
      return target || null;
    }

    target = target || ({} as TTarget);
    config = config || globalConfig || defaultConfig;

    forOwn(source, (v, k) => {
      const converters = map[k];
      if (converters && (!config.skipNullValues || v != null)) {
        converters.forEach(conv => convertProperty(v, conv, target));
      }
    });

    return target;
  };
}

function createMultiObjectConverter<TSource, TTarget>(
  singleConv: ObjectConverter<TSource, TTarget>
) {
  return (
    source: TSource[],
    targetFactory?: (source: TSource) => TTarget,
    config?: ObjectConverterConfig
  ): TTarget[] =>
    source &&
    source.map(i =>
      singleConv(i, targetFactory ? targetFactory(i) : null, config)
    );
}

class ConversionMapImpl<TSource, TTarget>
  implements ConversionMap<TSource, TTarget> {
  private readonly map: InternalConversionMap<TSource, TTarget> = {};
  private readonly reverseMap: InternalConversionMap<TTarget, TSource> = {};

  constructor(private readonly config: ObjectConverterConfig = defaultConfig) {}

  from<TPropName extends keyof TSource>(
    propName: TPropName
  ): FromPropertyConversionMap<TSource, TPropName, TTarget> {
    return new FromPropertyConversionMapImpl<TSource, TPropName, TTarget>(
      this,
      propName
    );
  }

  build(): ObjectConverter<TSource, TTarget> {
    return createObjectConverter<TSource, TTarget>(this.map, this.config);
  }

  buildMulti(): MultiObjectConverter<TSource, TTarget> {
    return createMultiObjectConverter(this.build());
  }

  buildReverse(): ObjectConverter<TTarget, TSource> {
    return createObjectConverter<TTarget, TSource>(
      this.reverseMap,
      this.config
    );
  }

  buildMultiReverse(): MultiObjectConverter<TTarget, TSource> {
    return createMultiObjectConverter(this.buildReverse());
  }

  add<
    TSourcePropName extends keyof TSource,
    TTargetPropName extends keyof TTarget
  >(conv: Conversion<TSource, TSourcePropName, TTarget, TTargetPropName>) {
    if (!this.map[conv.source]) {
      this.map[conv.source] = [];
    }
    this.map[conv.source].push(conv);
  }

  addReverse<
    TTargetPropName extends keyof TTarget,
    TSourcePropName extends keyof TSource
  >(conv: Conversion<TTarget, TTargetPropName, TSource, TSourcePropName>) {
    if (!this.reverseMap[conv.source]) {
      this.reverseMap[conv.source] = [];
    }
    this.reverseMap[conv.source].push(conv);
  }

  extends<TS extends Partial<TSource>, TT extends Partial<TTarget>>(
    parentMap: ConversionMap<TS, TT>
  ) {
    if (!hasInternalMaps(parentMap) && !hasParentMap(parentMap)) {
      throw new Error('Parent map is invalid.');
    }

    if (hasParentMap(parentMap)) {
      parentMap = parentMap.map;
    }

    const { map, reverseMap } = parentMap as WithInternalMaps<TS, TT>;
    forOwn(map, (convs: Conversion<TS, keyof TS, TT, keyof TT>[]) =>
      convs.forEach(conv => this.add(conv as any))
    );
    forOwn(reverseMap, (convs: Conversion<TT, keyof TT, TS, keyof TS>[]) =>
      convs.forEach(conv => this.addReverse(conv as any))
    );

    return this;
  }

  get fieldMappings() {
    return mapValues(this.map, convs =>
      convs.map(c => c.target)
    ) as FieldMappings<TSource, TTarget>;
  }

  get fieldReverseMappings() {
    return mapValues(this.reverseMap, convs =>
      convs.map(c => c.target)
    ) as FieldMappings<TTarget, TSource>;
  }
}

type WithInternalMap<TSource, TTarget> = {
  map: InternalConversionMap<TSource, TTarget>;
} & ConversionMap<TSource, TTarget>;

type WithInternalReverseMap<TSource, TTarget> = {
  reverseMap: InternalConversionMap<TTarget, TSource>;
} & ConversionMap<TSource, TTarget>;

type WithInternalMaps<TSource, TTarget> = WithInternalMap<TSource, TTarget> &
  WithInternalReverseMap<TSource, TTarget>;

type WithParentMap<TSource, TTarget> = {
  map: ConversionMapImpl<TSource, TTarget>;
} & ConversionMap<TSource, TTarget>;

function hasInternalMap<TSource, TTarget>(
  convMap: ConversionMap<TSource, TTarget>
): convMap is WithInternalMap<TSource, TTarget> {
  return 'map' in convMap;
}

function hasInternalReverseMap<TSource, TTarget>(
  convMap: ConversionMap<TSource, TTarget>
): convMap is WithInternalReverseMap<TSource, TTarget> {
  return 'reverseMap' in convMap;
}

function hasInternalMaps<TSource, TTarget>(
  convMap: ConversionMap<TSource, TTarget>
): convMap is WithInternalMaps<TSource, TTarget> {
  return hasInternalMap(convMap) && hasInternalReverseMap(convMap);
}

function hasParentMap<TSource, TTarget>(
  convMap: ConversionMap<TSource, TTarget>
): convMap is WithParentMap<TSource, TTarget> {
  return hasInternalMap(convMap) && convMap.map instanceof ConversionMapImpl;
}

export function createConversionMap<TSource, TTarget>(
  config?: ObjectConverterConfig
): ConversionMap<TSource, TTarget> {
  return new ConversionMapImpl(config);
}

export default createConversionMap;
