import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'currencySMS', pure: false}) // Pure false to update when language changes
export class CurrencyPipe implements PipeTransform {

  transform(
    value: any,
    currency: string,
    locale?: string): string | null {

    if (!value || isNaN(value)) {
      return null;
    }

    const result = value.toLocaleString(locale ? locale : 'de', {
      style: 'currency',
      currency: currency
    });

    return result;
  }
}
