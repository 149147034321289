import { Filter, MultiValueMatchingMode } from './filters';

export interface FilterValue {
  selected: boolean;
  title: string;
}

export interface AppliedFilters {
  [key: string]: Filter;
}

export interface FilterRecord {
  key: string;
  type: string; // keyof FilterRecords[type];
}

export type AvailableFilterRecords =
  | ValueRecord
  | MultiValueRecord
  | RangeRecord
  | DateRecord
  | SearchRecord;

export interface ValueRecord extends FilterRecord {
  type: 'value';
  value?: string | number | boolean;
}
export interface MultiValueRecord extends FilterRecord {
  type: 'multivalue';
  mode?: MultiValueMatchingMode;
  values?: (string | number | boolean)[];
}
export interface RangeRecord extends FilterRecord {
  type: 'range';
  min?: number;
  max?: number;
}
export interface DateRecord extends FilterRecord {
  type: 'date';
  from?: string; // ISO date string
  to?: string; // ISO date string
}
export interface SearchRecord extends FilterRecord {
  type: 'search';
  term?: string;
}

/* API */

export type Api<I> = (payload: ApiPayload<I>) => Promise<ApiResponse<I>>;

export interface ApiPayload<I> {
  type: 'items' | 'filterValues';
  list: string;
  records: FilterRecord[];
  filter?: FilterRecord;
}
export interface ApiResponse<I> {
  items?: I[];
  values?: any[];
  min?: any;
  max?: any;
}

// export type Api<I = {}> = <F, P extends ApiPayloadItems | ApiPayloadFilterValues<F>>(payload: P) =>
//   Promise<P extends ApiPayloadItems ? ApiResponseItems<I> : ApiResponseFilterValues<F>>;

// export interface ApiPayloadItems {
//   type: 'items';
//   list: string;
//   records: FilterRecord[];
// }
// export interface ApiPayloadFilterValues<F> {
//   type: 'filterValues';
//   filter: FilterRecord<F>;
//   records: FilterRecord[];
//   localFilterKeys: string[]; // return all item values for these keys so that local filters work?? does this even make sense for min max filters? return all items -> no better performance whatsoever
// }
// export interface ApiResponseItems<I> {
//   // type: 'items';
//   items: I[];
//   pagination?: Pagination; // Pagination on remote only works if no local filters are applied
// }
// export interface ApiResponseFilterValues<F> {
//   // type: 'filterValues';
//   values?: F[];
//   min?: F;
//   max?: F;
// }
// export type Pagination = FullPagination | NextPageOnlyPagination;
// export interface FullPagination {
//   itemCount: number;
//   pageSize: number;
//   pageNumber: number;
// }
// export interface NextPageOnlyPagination {
//   next: number;
// }
