import { BusinessAreaWithoutConvert } from './business-area';

export interface Portfolio {
  id: number;
  abbreviation: string;
  title: string;
}

export interface PortfolioWithBusinessAreas {
  id: number;
  abbreviation: string;
  name: string;
  businessArea: BusinessAreaWithoutConvert[];
}

export interface ApiPortfolio {
  Id: number;
  Title: string;
  Abbreviation: string;
}
