<div class="paginator paginator-wrapper">
  <button class="invisible-button" (click)="selectPrevious()">
    <mat-icon class="custom-text-color">keyboard_arrow_left</mat-icon>
  </button>
  <ng-container *ngIf="_selectedDate">
    <mat-select
      [value]="_selectedDate"
      (selectionChange)="setWithSurrounding($event.value)"
    >
      <mat-option *ngFor="let date of dateList" [value]="date"
        >{{ getDisplayedTimeUnit(date) }}
      </mat-option>
    </mat-select>
  </ng-container>
  <button class="invisible-button arrow" (click)="selectNext()">
    <mat-icon class="custom-text-color">keyboard_arrow_right</mat-icon>
  </button>
</div>
