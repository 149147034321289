export interface PspElement {
  id: number;
  orgId: string;
  profitCenter: string;
  costCenter: string;
  pspElementOld: string;
  pspElement: string;
  from: Date;
  to: Date;
  businessAreas?: any;
  projects?: any;
}

// Responses
export type PspElementGetMultipleResponse = PspElement[];
export type PspElementGetOneResponse = PspElement;
export type PspElementCreateOneResponse = PspElement;
export type PspElementDeleteOneResponse = Pick<PspElement, 'id'>;
export type PspElementUpdateOneResponse = PspElement;

// Requests
export type PspElementCreateOneRequest = Omit<
  PspElement,
  'id' | 'pspElementOld' | 'pspElement'
> &
  Partial<Pick<PspElement, 'pspElementOld' | 'pspElement'>>;
export type PspElementUpdateOneRequest = Partial<Omit<PspElement, 'id'>>;
